import { useTranslation } from "react-i18next";
import { useAuth } from "./auth";
import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { User, UserType } from "../models/user/types";
import { useQuery } from "react-query";
import { getUser } from "../services/user";
import { getToken, getUserId, isLogged } from "../services/localstorage";
import { LoginActions } from "../components/navbar/types";
import CryptoJS from "crypto-js";
import { getSendBirdData } from "../services/chat";

export const LoginContext = createContext<LoginActions>({
  isLogged: false,
  setIsLogged: () => isLogged,
  isComplete: false,
  setIsComplete: () => false,
});

export const useRoot = () => {
  const { t } = useTranslation(["routes"]);
  const navigate = useNavigate();
  const location = useLocation();
  const [theme, setTheme] = useState<"Light" | "Dark">("Light");
  const { data: user } = useQuery(["profile"], getUser, {
    enabled: !!getToken() && !!getUserId(),
  });
  const { data: sendBirdData } = useQuery(["sendBird"], getSendBirdData, {
    enabled: !!getToken() && !!getUserId(),
  });

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme") as "Light" | "Dark";
    setTheme(storedTheme || "Light");
  }, []);

  // Token authentication
  const auth = useAuth();

  // Login
  const [isLogged, setIsLogged] = useState(auth.haveToken);

  // Signup flow
  const [isComplete, setIsComplete] = useState(true);

  useEffect(() => {
    const sendToCompleteProfile = (user: User) => {
      setIsComplete(user.name !== "");
      if (user.name !== "") return;

      if (location.pathname !== "/" + t("complete_profile", { ns: "routes" })) {
        navigate("/" + t("complete_profile", { ns: "routes" }));
      }
    };

    if (user) {
      sendToCompleteProfile(user);
    }
  }, [user]);

  useEffect(() => {
    if (
      isLogged &&
      !isComplete &&
      location.pathname !== "/" + t("complete_profile", { ns: "routes" })
    ) {
      navigate("/" + t("complete_profile", { ns: "routes" }));
    }
  }, [location.pathname]);

  // User has proper permissions
  const typeMatch =
    CryptoJS.AES.decrypt(
      localStorage.getItem("userType") || "",
      process.env.REACT_APP_USER_TYPE || ""
    ).toString(CryptoJS.enc.Utf8) ===
    UserType.DRIVER_HOST + "type";

  const [isAuthorized, setIsAuthorized] = useState(typeMatch);

  useEffect(() => {
    if (user) {
      setIsAuthorized(user.type === UserType.DRIVER_HOST);
    }
  }, [user]);

  useEffect(() => {
    if (typeMatch) {
      setIsAuthorized(typeMatch);
    }
  }, [typeMatch]);

  // Dark / Light modes
  useEffect(() => {
    if (theme === "Dark") {
      document.documentElement.setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "Dark");
    }

    if (theme === "Light") {
      document.documentElement.setAttribute("data-theme", "light");
      localStorage.setItem("theme", "Light");
    }
  }, [theme]);

  const value = {
    isLogged,
    setIsLogged,
    isComplete,
    setIsComplete,
  };

  return {
    isLogged,
    value,
    user,
    t,
    isAuthorized,
    isComplete,
    theme,
    setTheme,
    sendBirdData,
  };
};
