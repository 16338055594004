import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { CompleteUserDTO } from "../../models/user/dtos";
import { getUser, updateUser } from "../../services/user";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { getToken, getUserId } from "../../services/localstorage";
import { useQuery } from "react-query";
import { LoginContext } from "../../hooks/root";
import {
  CompleteUserForm,
  completeProfileSchema,
} from "../../views/completeProfile/form";
import { verification } from "../../services/auth";

export const useCompleteProfile = () => {
  const { t } = useTranslation(["completeProfile", "routes"]);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const { setIsComplete } = useContext(LoginContext);

  const { data: user } = useQuery(["profile"], getUser, {
    enabled: !!getToken() && !!getUserId(),
  });

  const fetchCompleteProfile = async (values: CompleteUserDTO) => {
    try {
      // Update user
      const response = await updateUser({
        name: values.name,
        surName: values.surName,
        phone: "+" + values.phone,
      });

      // Add name as local storage and redirect to verify phone
      if (response?.status === 200) {
        setIsComplete(true);
        localStorage.setItem("name", values.name);
        navigate("/" + t("profile", { ns: "routes" }));
        verification();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMsg(error.response?.data.message);
        setOpenAlert(true);
      }
    }
  };

  // Redirect to root if it already has a complete profile
  useEffect(() => {
    if (user && user.name !== "" && user.surName !== "") {
      navigate(t("root", { ns: "routes" }));
    }
  }, [user]);

  const formikCompleteProfile = useFormik<CompleteUserForm>({
    initialValues: {
      name: "",
      surName: "",
      phone: "",
      privacyPolicies: false,
      termsAndConditions: false,
    },
    validationSchema: completeProfileSchema,
    onSubmit: fetchCompleteProfile,
  });

  return {
    t,
    formikCompleteProfile,
    openAlert,
    setOpenAlert,
    errorMsg,
  };
};
