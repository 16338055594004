import acBackground from "./ac_background.png";
import campaigns from "./campaigns.svg";
import free from "./free.svg";
import graphs from "./graphs.svg";
import manage from "./manage.svg";
import money from "./money.svg";
import politics from "./politics.svg";
import publicPerk from "./public.svg";
import shield from "./shield.svg";
import steps from "./steps.png";
import stepsMobile from "./steps_mobile.png";

export default {
  acBackground,
  campaigns,
  free,
  graphs,
  manage,
  money,
  politics,
  publicPerk,
  shield,
  steps,
  stepsMobile,
};
