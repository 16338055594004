import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./Footer.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import footer from "../../assets/images/footer/index";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Box, MenuItem, Select } from "@mui/material";
import i18n from "../../i18n";
import navbarImages from "../../assets/images/navbar";
import { changeLngRoute } from "../../lib/helpers/routesHandler";

interface FooterProps {
  setTheme: Dispatch<SetStateAction<"Light" | "Dark">>;
  theme: "Light" | "Dark";
}

export const Footer = ({ setTheme, theme }: FooterProps) => {
  const { t } = useTranslation(["footer", "routes"]);
  const [language, setLanguage] = useState(i18n.language);
  const navigate = useNavigate();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    navigate(changeLngRoute(lng));
  };

  useEffect(() => {
    if (language !== i18n.language) {
      setLanguage(i18n.language);
    }
  }, [i18n.language]);

  return (
    <footer className="footer-container w-100">
      <div className="py-6 text-muted">
        <div className="container">
          <div className="row">
            <div className="social-language-list col-lg-3 mb-2 d-none d-lg-block">
              <img src={navbarImages.logoRomioBlack} alt="" />
              <div className="social">
                <div className="d-flex">
                  <a
                    href="https://facebook.com/romio.ridess"
                    target="_blank"
                    className="social-item"
                    rel="noreferrer"
                    aria-label="go to our facebook page"
                  >
                    <img src={footer.facebookBlack} alt="" />
                  </a>
                  <a
                    href="https://www.instagram.com/romio.rides/"
                    target="_blank"
                    className="social-item"
                    rel="noreferrer"
                    aria-label="go to our instagram page"
                  >
                    <img src={footer.instagramBlack} alt="" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/romio-mx/"
                    target="_blank"
                    className="social-item"
                    aria-label="go to our linkedin page"
                    rel="noreferrer"
                  >
                    <img src={footer.linkedinBlack} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 mb-2 mb-lg-0">
              <p className="mb-3 footer-titles" data-testid="support-title">
                {t("support.title")}
              </p>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a
                    href="mailto:soporte@romio.mx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={footer.mailBlack} alt="" />
                    {t("support.email")}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="tel:+523318866005" target="_blank" rel="noreferrer">
                    <img src={footer.phoneBlack} alt="" />
                    {t("support.phone")}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://wa.me/523318866005"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={footer.whatsappBlack} alt="" />
                    {t("support.phone")}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://ayuda.balety.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={footer.helpBlack} alt="" />
                    {t("support.help")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 mb-2 mb-lg-0">
              <p className="mb-3 footer-titles" data-testid="rent-title">
                {t("rent.title")}
              </p>
              <ul className="list-unstyled">
                <li>
                  <HashLink
                    to={`${t("root", { ns: "routes" })}#h_navbar_container`}
                  >
                    {t("rent.rent_a_car")}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={`${t("host", { ns: "routes" })}#h_navbar_container`}
                  >
                    {t("rent.become_a_host")}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={`${t("commercial_host", {
                      ns: "routes",
                    })}#h_navbar_container`}
                  >
                    {t("rent.commercial_host")}
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-2 mb-lg-0">
              <p className="mb-3 footer-titles" data-testid="information-title">
                {t("information.title")}
              </p>
              <ul className="list-unstyled">
                <li>
                  <a
                    target="_blank"
                    href={
                      "https://upcdn.io/12a1xrm/raw/balety.com/documents/contrato-de-arrendamiento.pdf"
                    }
                  >
                    {t("information.leasing_contract")}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={
                      "https://upcdn.io/12a1xrm/raw/balety.com/documents/aviso-de-privacidad.pdf"
                    }
                  >
                    {t("information.privacy_policies")}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={
                      "https://upcdn.io/12a1xrm/raw/balety.com/documents/terminos-y-condiciones-de-uso.pdf"
                    }
                  >
                    {t("information.terms_and_conditions")}
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://ayuda.balety.com">
                    {t("information.faq")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="social-language-list col-md-6 col-lg-2 mb-2">
              <p className="mb-3 footer-titles" data-testid="settings-title">
                {t("settings.title")}
              </p>
              <div className="language">
                <p className="subtitle">{t("language.title")}</p>
                <Select
                  className="dropdown-language"
                  value={language}
                  label="language"
                  onChange={(event) => {
                    setLanguage(event.target.value);
                    if (event.target.value === "en") {
                      changeLanguage("en");
                      return;
                    }
                    changeLanguage("es");
                  }}
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: "flex" }}>
                        <img
                          className="flag-icon"
                          src={value === "es" ? footer.mexico : footer.usa}
                          alt="flag-icon"
                        />
                        {value === "en" ? "English" : "Español"}
                      </Box>
                    );
                  }}
                >
                  <MenuItem value={"en"}>
                    <img
                      className="flag-icon"
                      src={footer.usa}
                      alt="flag-icon"
                    />
                    English
                  </MenuItem>
                  <MenuItem value={"es"}>
                    <img
                      className="flag-icon"
                      src={footer.mexico}
                      alt="flag-icon"
                    />
                    Español
                  </MenuItem>
                </Select>
              </div>
              <div className="theme">
                <p className="subtitle">{t("theme.title")}</p>
                <Select
                  className="dropdown-theme"
                  value={theme}
                  label="theme"
                  onChange={(event) => {
                    const selected = event.target.value as "Light" | "Dark";
                    setTheme(selected);
                  }}
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: "flex" }}>
                        <img
                          className="flag-icon"
                          src={value === "Light" ? footer.light : footer.dark}
                          alt="flag-icon"
                        />
                        {value === "Light" ? t("theme.light") : t("theme.dark")}
                      </Box>
                    );
                  }}
                >
                  <MenuItem value={"Light"}>
                    <img
                      className="flag-icon"
                      src={footer.light}
                      alt="flag-icon"
                    />
                    {t("theme.light")}
                  </MenuItem>
                  <MenuItem value={"Dark"}>
                    <img
                      className="flag-icon"
                      src={footer.dark}
                      alt="flag-icon"
                    />
                    {t("theme.dark")}
                  </MenuItem>
                </Select>
              </div>
            </div>
            <div className="social-language-list col-md-12 mt-2 mb-2 d-md-block d-lg-none d-sm-block d-block">
              <img src={navbarImages.logoRomioBlack} alt="" />
              <div className="social">
                <div className="d-flex">
                  <a
                    href="https://facebook.com/romio.ridess"
                    target="_blank"
                    className="social-item"
                    rel="noreferrer"
                  >
                    <img src={footer.facebookBlack} alt="" />
                  </a>
                  <a
                    href="https://www.instagram.com/romio.rides/"
                    target="_blank"
                    className="social-item"
                    rel="noreferrer"
                  >
                    <img src={footer.instagramBlack} alt="" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/romio-mx/"
                    target="_blank"
                    className="social-item"
                    rel="noreferrer"
                  >
                    <img src={footer.linkedinBlack} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
