import { FC, useState, Dispatch, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import "./styles.scss";
import authenticationImages from "../../../assets/images/authentication/index";
import navbarImages from "../../../assets/images/navbar";
import { useFormik } from "formik";
import { Auth } from "../../../models/auth/types";
import * as Yup from "yup";
import { sha256 } from "js-sha256";
import { login } from "../../../services/auth";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

const modalLoginContinue: Yup.SchemaOf<Auth> = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string()
    .min(3, "La clave debe contener al menos 3 caracteres")
    .required("La contraseña es requerida"),
});

interface LoginModalProps {
  setErrorMsg: Dispatch<SetStateAction<string | null>>;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  setOpen: () => void;
}

export const LoginContinueModal: FC<LoginModalProps> = ({
  isOpen,
  setOpen,
  setOpenAlert,
  setErrorMsg,
}) => {
  const { t } = useTranslation(["navbar"]);
  const queryClient = useQueryClient();
  const [password, setPassword] = useState<boolean>(false);

  const fetchLoginContinue = async (values: Auth) => {
    try {
      await login({
        email: values.email,
        password: sha256(values.password),
      });
      queryClient.invalidateQueries(["profile"]);
      setOpen();
      window.location.reload();
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMsg(error.response?.data.message);
        setOpenAlert(true);
      }
    }
  };

  const formikLoginContinue = useFormik<Auth>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: modalLoginContinue,
    onSubmit: fetchLoginContinue,
  });

  return (
    <Modal show={isOpen} className="login-continue-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={setOpen}>
          <img src={navbarImages.cross} alt="" />
        </button>
        <h2>{t("un_auth_navbar_menu.login.title")}</h2>
      </div>
      <div className="modal-body">
        <h3>{t("un_auth_navbar_menu.login.login_account")}</h3>
        <div className="log-collapse login-content" id="loginContent">
          <div className="form">
            <p className="dropdown-item">
              {t("un_auth_navbar_menu.login.email.label")}
            </p>
            <input
              type="email"
              id="emailP"
              className="log-input"
              placeholder={t("un_auth_navbar_menu.login.email.placeholder")}
              onChange={formikLoginContinue.handleChange("email")}
              onBlur={formikLoginContinue.handleBlur("email")}
              value={formikLoginContinue.values.email}
            />
            <p className="dropdown-item">
              {t("un_auth_navbar_menu.login.password.label")}
            </p>
            <div className="d-flex input-group">
              <input
                type={password ? "text" : "password"}
                className="number-input modal-change-pass-ConfirmPass"
                placeholder={t(
                  "un_auth_navbar_menu.login.password.placeholder"
                )}
                onChange={formikLoginContinue.handleChange("password")}
                onBlur={formikLoginContinue.handleBlur("password")}
                value={formikLoginContinue.values.password}
              />
              <div id="eye_password2" className="input-group-addon">
                <img
                  src={
                    password
                      ? authenticationImages.eye
                      : authenticationImages.crossEye
                  }
                  onClick={() => setPassword((password) => !password)}
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          <a
            className="forgot"
            href="~/views/authentication/forgotten_password.aspx"
          >
            {t("un_auth_navbar_menu.login.forgot_password")}
          </a>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-orange"
            onClick={() => formikLoginContinue.handleSubmit()}
          >
            {t("un_auth_navbar_menu.login.get_into")}
          </button>
        </div>
        <div className="align-items-center w-100 d-flex">
          <div className="dropdown-divider w-50"></div>
          <p className="my-3 or mx-2">Ó</p>
          <div className="dropdown-divider w-50"></div>
        </div>
        <div className="d-flex">
          <Link
            to={"/" + t("signup", { ns: "routes" })}
            className="btn btn-create"
          >
            {t("no_auth_navbar_collapse.create_an_account")}
          </Link>
        </div>
      </div>
    </Modal>
  );
};
