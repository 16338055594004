import { FC } from "react";
import { Modal } from "react-bootstrap";
import { ModalProps } from "../types";
import navbarImages from "../../../assets/images/navbar";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AcceptingRequest: FC<ModalProps> = ({ isOpen, setOpen }) => {
  const { t } = useTranslation(["modals"]);
  const navigate = useNavigate();

  return (
    <Modal show={isOpen} className="accepting-request-modal" backdrop="static">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setOpen();
            navigate(
              "/" +
                t("hosts", { ns: "routes" }) +
                "/" +
                t("trips", {
                  ns: "routes",
                })
            );
          }}
        >
          <img src={navbarImages.cross} alt="" />
        </button>
        <h2>{t("accepting_request.title")}</h2>
      </div>
      <div className="modal-body">
        <h3>{t("accepting_request.subtitle")}</h3>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-orange">
          <p>{t("accepting_request.messages")}</p>
        </button>
      </div>
    </Modal>
  );
};
