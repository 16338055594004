import a_cars from "../../../../../assets/images/a_cars";
import { FC, useContext, useRef } from "react";
import { ShowContext } from "../../../../../views/car/show/types";
import { getTransmission } from "../../../../../views/car/index/types";
import {
  Car,
  IsEcofriendly,
  Transmission,
} from "../../../../../models/car/types";
import { CarInformation } from "../carInformation/CarInformation";
import { Chip } from "@mui/material";
import { useExperience } from "../../../../../hooks/exp";
import { HostCard } from "../hostCard/HostCard";
import { useTranslation } from "react-i18next";
import "./CarSpecs.scss";
import { useTypes } from "../../../../../hooks/car/show/useTypes";

export const CardInformation = () => {
  const { car, host } = useContext(ShowContext);

  return (
    <div className="card-info">
      <h5 className="card-title" id="carro">
        {car && car.brand + " " + car.model + " " + car.year}
      </h5>
      {car && <CarSpecs car={car} />}
      {car && <CarExperiences car={car} />}

      <HostCard userId={host?.id || 0} />
      <div className="car-info-des">
        <CarInformation />
      </div>
    </div>
  );
};

export const CarSpecs: FC<{ car: Car; className?: string }> = ({
  car,
  className,
}) => {
  const { t } = useTranslation(["car", "results"]);
  const CAR_TYPES = useTypes();
  const transmissionValue = useRef(
    getTransmission(car ? car.transmission : Transmission.AUTOMATIC)
  );
  const eco = ["", "electric", "hybrid"];

  if (!car) {
    return <></>;
  }

  return (
    <div className={`car-specs ${className}`}>
      <div className="left">
        <div className="d-flex car-data-item">
          <div className="icon-container">
            <img className="icon" src={a_cars.seats} />
          </div>
          <p>
            {car?.numSeats} {t("car:show.car_form.card_info.seats")}
          </p>
        </div>
        <div className="d-flex car-data-item">
          <div className="icon-container">
            <img src={a_cars.bag} alt="bag" className="icon" />
          </div>
          <p>
            {car.numBags} {t("car:show.car_form.card_info.bags")}
          </p>
        </div>
        {car && car.isEcoFriendly !== IsEcofriendly.NEGATIVE ? (
          <div className="d-flex car-data-item">
            <div className="icon-container">
              <img src={a_cars.ecoType} alt="is ecological" className="icon" />
            </div>
            <p>{t("car:show.car_form.card_info." + eco[car.isEcoFriendly])}</p>
          </div>
        ) : null}
      </div>
      <div className="right">
        <div className="d-flex car-data-item">
          <div className="icon-container">
            <img
              src={a_cars.transmission}
              alt="transmission type"
              className="icon"
            />
          </div>
          <p>{transmissionValue.current}</p>
        </div>
        {car.type !== undefined && car.type !== null && (
          <div className="d-flex car-data-item">
            {CAR_TYPES[car.type] && (
              <>
                <div className="icon-container">
                  <img
                    src={CAR_TYPES[car.type].src}
                    alt="type"
                    className="car-types-icon"
                  />
                </div>
                <p>{CAR_TYPES[car.type].label}</p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const CarExperiences: FC<{ car: Car }> = ({ car }) => {
  const EXPERIENCES = useExperience();

  return (
    <div className="car-experiences">
      {car?.experiences &&
        car.experiences.split("").map((exp, index) => {
          const expData = EXPERIENCES.find(
            (exp) => exp.index === index.toString()
          );

          return (
            exp === "1" &&
            expData && (
              <Chip
                key={expData.label + "" + index}
                avatar={<img src={expData.src} />}
                label={expData.label}
                variant="outlined"
              />
            )
          );
        })}
    </div>
  );
};
