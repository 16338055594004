import { Helmet } from "react-helmet";
import helpImages from "../../assets/images/help";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./PageNotFound.scss";

export const PageNotFound = () => {
  const { t } = useTranslation(["help"]);
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <section className="page-not-found">
        <img src={helpImages.error404} alt="error404" className="error-404" />
        <h1 className="title">{t("error")}</h1>
        <h2 className="subtitle">{t("not_found_page")}</h2>
        <div className="redirect-section">
          <p>{t("redirect.title")}</p>
          <ul>
            <li>
              <Link
                to={
                  "/" +
                  t("results", {
                    ns: "routes",
                  }) +
                  "?destinationId=1&state=&latitud=20.6595382&longitud=-103.3494376&dateInitial=09%2F08%2F2023&hourInitial=12%3A00&dateEnd=12%2F08%2F2023&hourEnd=12%3A00&experiences=000000000&type=012345&transmission=01"
                }
              >
                {t("redirect.car_rental")}
              </Link>
            </li>
            <li>
              <Link
                to={
                  "/" +
                  t("host", {
                    ns: "routes",
                  })
                }
              >
                {t("redirect.upload_car")}
              </Link>
            </li>
            <li>
              <Link
                to={
                  "/" +
                  t("commercial_host", {
                    ns: "routes",
                  })
                }
              >
                {t("redirect.host_commercial")}
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
