import { FC, useContext, useEffect, useState } from "react";
import { DropDownMenu } from "../dropdownMenu/component";
import navbarImages from "../../../../assets/images/navbar/index";
import authenticationImages from "../../../../assets/images/authentication/index";
import modalImages from "../../../../assets/images/modals/index";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { loginSchema } from "../loginCollapse/form";
import { useFormik } from "formik";
import { Auth } from "../../../../models/auth/types";
import {
  AuthContext,
  AuthNavbarMenuProps,
  NavbarMenuProps,
} from "../../../../components/navbar/types";
import { NavDropdown } from "react-bootstrap";
import { UserType } from "../../../../models/user/types";
import useProfileData from "../../../../components/profileData/useProfileData";

export const NavbarMenu: FC<NavbarMenuProps> = ({
  logged,
  user,
  routeType,
}) => {
  return (
    <div className="navbar-unauthenticated">
      {logged ? (
        <AuthNavbarMenu user={user} routeType={routeType} />
      ) : (
        <UnAuthNavbarMenu />
      )}
    </div>
  );
};

const UnAuthNavbarMenu = () => {
  const { t } = useTranslation(["navbar", "routes"]);
  const [password, setPassword] = useState<boolean>(false);
  const auth = useContext(AuthContext);

  const formikLogin = useFormik<Auth>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: auth.fetchLogin,
  });

  return (
    <>
      <Link
        to={t("root", { ns: "routes" })}
        id="dropdown_toggle1"
        data-testid="rent-menu"
        className="links text-decoration-none dropdown"
      >
        {t("un_auth_navbar_menu.rent")}
      </Link>
      <NavDropdown
        title={t("un_auth_navbar_menu.earn_money_with_my_car")}
        className="links text-decoration-none earn-money"
        data-testid="earn-money-menu"
        id="ac_dropdown"
      >
        <Link className="dropdown-item" to={t("host", { ns: "routes" })}>
          {t("un_auth_navbar_menu.upload_my_car")}
        </Link>
        <NavDropdown.Divider />
        <Link
          className="dropdown-item"
          to={t("commercial_host", { ns: "routes" })}
        >
          {t("un_auth_navbar_menu.own_fleets")}
        </Link>
      </NavDropdown>
      <NavDropdown
        title={t("un_auth_navbar_menu.login.title")}
        data-testid="login-menu-dropdown"
        className="links text-decoration-none login"
        style={{ display: "block" }}
      >
        <img className="triangle-menu" src={navbarImages.triangle} alt="" />
        <div className="form">
          <div>
            <p className="dropdown-item email">
              {t("un_auth_navbar_menu.login.email.label")}
            </p>
            <input
              id="txtEmail"
              type="text"
              data-testid="email"
              className="log-input email-input"
              placeholder={t("un_auth_navbar_menu.login.email.placeholder")}
              onChange={formikLogin.handleChange("email")}
              onBlur={formikLogin.handleBlur("email")}
              value={formikLogin.values.email}
            />
          </div>
          <div>
            <p className="dropdown-item password">
              {t("un_auth_navbar_menu.login.password.label")}
            </p>
            <div className="d-flex">
              <input
                id="txtPassword"
                data-testid="password"
                type={password ? "text" : "password"}
                className="log-input pss-input"
                placeholder={t(
                  "un_auth_navbar_menu.login.password.placeholder"
                )}
                onChange={formikLogin.handleChange("password")}
                onBlur={formikLogin.handleBlur("password")}
                value={formikLogin.values.password}
              />
              <div id="eye_password" className="input-group-addon">
                <img
                  src={
                    password
                      ? authenticationImages.eye
                      : authenticationImages.crossEye
                  }
                  onClick={() => setPassword((password) => !password)}
                  aria-hidden="true"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="forgot-container">
            <Link
              className="forgot dropdown-item"
              to={t("recover_password", { ns: "routes" })}
            >
              {t("un_auth_navbar_menu.login.forgot_password")}
            </Link>
          </div>
          <button
            type="button"
            data-testid="login"
            className="btn btn-orange"
            onClick={() => formikLogin.handleSubmit()}
            id="bttLogIn"
          >
            {t("un_auth_navbar_menu.login.get_into")}
          </button>
          <div className="other-signin" style={{ display: "none" }}>
            <div className="d-flex align-items-center w-100">
              <hr />
              <p className="or">{t("un_auth_navbar_menu.login.enter_via")}</p>
              <hr />
            </div>
            <div className="d-flex flex-column">
              <Link
                className="btn btn-social"
                to={t("signup", { ns: "routes" })}
              >
                <img src={modalImages.facebook} alt="" />
                {t("un_auth_navbar_menu.login.facebook")}
              </Link>
              <Link
                className="btn btn-social"
                to={t("signup", { ns: "routes" })}
              >
                <img src={modalImages.google} alt="" />
                {t("un_auth_navbar_menu.login.google")}
              </Link>
              <Link
                className="btn btn-social"
                to={t("signup", { ns: "routes" })}
              >
                <img src={modalImages.apple} alt="" />
                {t("un_auth_navbar_menu.login.apple")}
              </Link>
            </div>
          </div>
        </div>
      </NavDropdown>
    </>
  );
};

const AuthNavbarMenu: FC<AuthNavbarMenuProps> = ({ user, routeType }) => {
  const [menuUserShow, setMenuUserShow] = useState<boolean>(false);
  const { t } = useTranslation(["navbar", "routes"]);
  const { imageProfile } = useProfileData();

  useEffect(() => {
    setMenuUserShow(false);
  }, [location.pathname]);

  return (
    <>
      <Link
        data-testid="rent-menu"
        className="links nav-item text-decoration-none dropdown"
        to={
          routeType === "driver"
            ? t("root", { ns: "routes" })
            : t("my_cars", { ns: "routes" })
        }
      >
        {routeType === "driver"
          ? t("auth_navbar_menu.driver.rent")
          : t("auth_navbar_menu.host.my_cars")}
      </Link>
      <Link
        className="links nav-item text-decoration-none dropdown"
        to={
          (routeType === "driver"
            ? t("drivers", { ns: "routes" })
            : t("hosts", { ns: "routes" })) +
          "/" +
          t("trips", { ns: "routes" })
        }
      >
        {t("auth_navbar_menu.reservations")}
      </Link>
      <Link
        className="links nav-item text-decoration-none dropdown"
        to={
          routeType === "driver"
            ? t("drivers", { ns: "routes" }) +
              "/" +
              t("favorites", { ns: "routes" })
            : t("hosts", { ns: "routes" }) +
              "/" +
              t("progress", { ns: "routes" })
        }
      >
        {routeType === "driver"
          ? t("auth_navbar_menu.driver.favorites")
          : t("auth_navbar_menu.host.progress")}
      </Link>
      <a
        href="https://ayuda.balety.com"
        target="_blank"
        rel="noreferrer"
        className="links nav-item text-decoration-none dropdown"
      >
        {t("auth_navbar_menu.help")}
      </a>
      {user?.type === UserType.DRIVER && (
        <Link
          data-testid="upload-car"
          to={t("host", { ns: "routes" })}
          className="links btn btn-upload-car"
          style={{
            background:
              "linear-gradient(to right,rgb(255, 127, 200),rgba(250, 189, 200, 1))",
            color: "#fff",
          }}
        >
          {t("un_auth_navbar_menu.upload_my_car")}
        </Link>
      )}
      <Link
        to={
          routeType === "driver"
            ? t("drivers", { ns: "routes" }) + "/" + t("chat", { ns: "routes" })
            : t("hosts", { ns: "routes" }) + "/" + t("chat", { ns: "routes" })
        }
        aria-label="go to messages"
        className="btn"
      >
        <img
          src={
            routeType === "driver"
              ? navbarImages.bMessages
              : navbarImages.bMessages
          }
          id="h_messages_button"
          alt=""
        />
      </Link>
      <div className="dropdown">
        <button
          className="btn"
          style={{ border: 0, opacity: 1 }}
          type="button"
          aria-label="profile-settings"
          disabled={menuUserShow}
          onClick={() => setMenuUserShow((prev) => !prev)}
        >
          <img
            src={imageProfile?.url ? imageProfile.url : navbarImages.bLogin}
            id="h_login_button"
            className="profile-image"
            alt=""
          />
        </button>
        {menuUserShow && user && (
          <DropDownMenu
            user={user}
            actionClick={() => setMenuUserShow((prev) => !prev)}
          />
        )}
      </div>
    </>
  );
};
