import "./Home.scss";
import h_homepageImage from "../../assets/images/home/h_homepage";
import { SplitInformation } from "../../lib/components/homeTools/splitInformation/component";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NavHomeFilter } from "../../components/navHomeFilter/component";
import { Helmet } from "react-helmet";
import { CarSuggestions } from "../../lib/components/carTools/carForm/carSuggestions/CarSuggestions";
import { Testimony } from "../../lib/components/homeTools/testimony/testimony";
import { DestinationCarousel } from "../../lib/components/homeTools/destinationCarousel/destinationCarousel";
import { useWindowSize } from "../../hooks/windowSize";
import { getToken } from "../../services/localstorage";
import h_homepageImages from "../../assets/images/home/h_homepage";
import { Perks } from "../../lib/components/homeTools/perks/perks";
import { Steps } from "../../lib/components/homeTools/steps/steps";

export const Home = () => {
  const { t } = useTranslation(["home", "routes"]);
  const windowSize = useWindowSize();

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta property="og:title" content={t("seo.og_title")} />
        <meta property="og:description" content={t("seo.og_description")} />
        <meta name="robots" content="index,follow" />

        <link rel="canonical" href="https://romio.mx" />
        <meta property="og:url" content="https://romio.mx" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={t("seo.og_locale")} />
        <link rel="alternate" hrefLang="es" href="https://romio.mx" />
        <link rel="alternate" hrefLang="en" href="https://romio.mx/en" />
        <link rel="alternate" hrefLang="x-default" href="https://romio.mx" />
      </Helmet>
      <main id="u_homepage">
        <div className="un-find">
          <NavHomeFilter />
          <div className="main-section">
            <div className="main-information">
              <h1 className="title" data-testid="steps-title">
                {t("steps.title")}
              </h1>
              <h2 className="subtitle">{t("steps.perfect_car")}</h2>
            </div>
            <img
              className="home-main-image"
              rel="preload"
              src={
                windowSize < 800
                  ? h_homepageImage.mainMobile
                  : h_homepageImage.main
              }
              alt="a women in a car"
              loading="lazy"
            />
          </div>
        </div>
      </main>
      <div className="steps-container">
        <Steps
          stepList={[
            <div className="step">
              <img src={h_homepageImage.unOne} alt="number one" />
              <div className="step-info">
                <h3 data-testid="step-one-title">
                  {t("steps.step_one.register")}
                </h3>
                <p>{t("steps.step_one.create_account")}</p>
              </div>
            </div>,
            <div className="step">
              <img src={h_homepageImage.unTwo} alt="number two" />
              <div className="step-info">
                <h3 data-testid="step-two-title">
                  {t("steps.step_two.choose_car")}
                </h3>
                <p>{t("steps.step_two.feels_like_yours")}</p>
              </div>
            </div>,
            <div className="step">
              <img src={h_homepageImage.unThree} alt="number three" />
              <div className="step-info">
                <h3 data-testid="step-three-title">
                  {t("steps.step_three.enjoy")}
                </h3>
                <p>{t("steps.step_three.drive_safely")}</p>
              </div>
            </div>,
          ]}
          image={
            <img
              src={
                windowSize < 800
                  ? h_homepageImage.stepsMobile
                  : h_homepageImage.steps
              }
              alt="a person approaching a car"
            />
          }
        />
      </div>

      <DestinationCarousel />

      <Perks
        title={
          <div className="title-container">
            <h2 data-testid="benefits-title" className="title">
              {t("benefits.title")}
            </h2>
          </div>
        }
        perkList={[
          <div className="benefit">
            <img src={h_homepageImage.safeTravel} alt="a way to ubication" />
            <div className="benefit-information">
              <h3>{t("benefits.safe_travels.title")}</h3>
              <p>{t("benefits.safe_travels.description")}</p>
            </div>
          </div>,
          <div className="benefit">
            <img src={h_homepageImage.easyRent} alt="a way to ubication" />
            <div className="benefit-information">
              <h3>{t("benefits.easy_rent.title")}</h3>
              <p>{t("benefits.easy_rent.description")}</p>
            </div>
          </div>,
          <div className="benefit">
            <img src={h_homepageImage.totalAssistance} alt="people talking" />
            <div className="benefit-information">
              <h3>{t("benefits.total_assistance.title")}</h3>
              <p>{t("benefits.total_assistance.description")}</p>
            </div>
          </div>,
          <div className="benefit">
            <img src={h_homepageImage.transparency} alt="an assistant" />
            <div className="benefit-information">
              <h3>{t("benefits.transparency.title")}</h3>
              <p>{t("benefits.transparency.description")}</p>
            </div>
          </div>,
          <div className="benefit">
            <img
              src={h_homepageImage.trustedCommunity}
              alt="trusted-community"
            />
            <div className="benefit-information">
              <h3>{t("benefits.trusted_community.title")}</h3>
              <p>{t("benefits.trusted_community.description")}</p>
            </div>
          </div>,
          <div className="benefit">
            <img src={h_homepageImage.fairPrices} alt="a bill crossed" />
            <div className="benefit-information">
              <h3>{t("benefits.fair_prices.title")}</h3>
              <p>{t("benefits.fair_prices.description")}</p>
            </div>
          </div>,
        ]}
      />

      <article className="un-info">
        <SplitInformation
          title={
            <h3 data-testid="transparency-title">
              {t("transparency_information.title")}
            </h3>
          }
          subtitle={
            <h4 data-testid="transparency-subtitle">
              {t("transparency_information.distinguishes_us")}
            </h4>
          }
          content={
            <div className="text-center">
              <p>
                <Trans>{t("transparency_information.feel_confident")}</Trans>
              </p>
              <Trans>
                <p>{t("transparency_information.get_car_choosed")}</p>
              </Trans>
              <Link
                to={
                  getToken()
                    ? "/" +
                      t("results", { ns: "routes" }) +
                      "?destinationId=0&state=&latitud=20.6595382&longitud=-103.3494376&dateInitial=09%2F08%2F2023&hourInitial=12%3A00&dateEnd=12%2F08%2F2023&hourEnd=12%3A00&experiences=000000000&type=012345&transmission=01"
                    : "/" + t("signup", { ns: "routes" })
                }
                className="btn btn-orange"
              >
                {t("transparency_information.rent_car")}
              </Link>
            </div>
          }
          image={
            <img
              src={h_homepageImage.clarity}
              className="image clarity-image"
              alt="a person over a car"
            />
          }
          componentColor={""}
          componentOrder={"order-bottom-right"}
        />
        <div className="row un-sec">
          <div className="content">
            <div className="col-12 col-sm-12 col-md-6 col-xl-5 content-text">
              <h3 data-testid="insured-trips-title">
                {t("insured_trips.title")}
              </h3>
              <h4 data-testid="insured-trips-subtitle">
                {t("insured_trips.never_alone")}
              </h4>
              <p className="mb-4 mt-1">
                <Trans>{t("insured_trips.our_priority")}</Trans>
                <a href="" className="pink">
                  {t("insured_trips.know_more")}
                </a>
              </p>
              <div className="card">
                <div className="card-body">
                  <ul className="list-left">
                    <li>
                      <img src={h_homepageImage.checkInsurance} alt="" />
                      {t("insured_trips.insurance_summary.civil_liability")}
                    </li>
                    <li>
                      <img src={h_homepageImage.checkInsurance} alt="" />
                      {t("insured_trips.insurance_summary.material_damage")}
                    </li>
                    <li>
                      <img src={h_homepageImage.checkInsurance} alt="" />
                      {t("insured_trips.insurance_summary.total_theft")}
                    </li>
                    <li>
                      <img src={h_homepageImage.checkInsurance} alt="" />
                      {t(
                        "insured_trips.insurance_summary.medical_expenses_occupants"
                      )}
                    </li>
                    <li>
                      <img src={h_homepageImage.checkInsurance} alt="" />
                      {t("insured_trips.insurance_summary.legal_expenses")}
                    </li>
                    <li>
                      <img src={h_homepageImage.checkInsurance} alt="" />
                      {t("insured_trips.insurance_summary.roadside_assistance")}
                    </li>
                  </ul>
                </div>
              </div>
              {windowSize > 767 && (
                <Link
                  to={
                    getToken()
                      ? t("results", { ns: "routes" }) +
                        "?destinationId=0&state=&latitud=20.6595382&longitud=-103.3494376&dateInitial=09%2F08%2F2023&hourInitial=12%3A00&dateEnd=12%2F08%2F2023&hourEnd=12%3A00&experiences=000000000&type=012345&transmission=01"
                      : t("signup", { ns: "routes" })
                  }
                  className="btn btn-orange btn-bottom"
                >
                  {t("transparency_information.rent_car")}
                </Link>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-6 mb-5 mt-4 image">
              <img
                src={h_homepageImage.insurance}
                className="w-100"
                alt="people happy, and qualitas and romio logos"
              />
            </div>
            {windowSize < 767 && (
              <Link
                to={
                  getToken()
                    ? t("results", { ns: "routes" }) +
                      "?destinationId=0&state=&latitud=20.6595382&longitud=-103.3494376&dateInitial=09%2F08%2F2023&hourInitial=12%3A00&dateEnd=12%2F08%2F2023&hourEnd=12%3A00&experiences=000000000&type=012345&transmission=01"
                    : t("signup", { ns: "routes" })
                }
                className="btn btn-orange btn-bottom"
              >
                {t("transparency_information.rent_car")}
              </Link>
            )}
          </div>
        </div>

        <Testimony
          componentColor={""}
          testimonies={[
            {
              image: (
                <img src={h_homepageImages.firstTestimony} alt="a person" />
              ),
              name: (
                <h3 className="name">
                  {t("testimony_container.first_testimony.name")}
                </h3>
              ),
              testimony: (
                <p className="testimony-text">
                  {t("testimony_container.first_testimony.testimony")}
                </p>
              ),
            },
            {
              image: (
                <img src={h_homepageImages.secondTestimony} alt="a person" />
              ),
              name: (
                <h3 className="name">
                  {t("testimony_container.second_testimony.name")}
                </h3>
              ),
              testimony: (
                <p className="testimony-text">
                  {t("testimony_container.second_testimony.testimony")}
                </p>
              ),
            },
            {
              image: (
                <img src={h_homepageImages.thirdTestimony} alt="a person" />
              ),
              name: (
                <h3 className="name">
                  {t("testimony_container.third_testimony.name")}
                </h3>
              ),
              testimony: (
                <p className="testimony-text">
                  {t("testimony_container.third_testimony.testimony")}
                </p>
              ),
            },
          ]}
        />

        <SplitInformation
          title={
            <h3 data-testid="trusted-community-title">
              {t("trusted_community.title")}
            </h3>
          }
          subtitle={
            <h4 data-testid="trusted-community-subtitle">
              {t("trusted_community.connecting_experiences")}
            </h4>
          }
          content={
            <>
              <p>
                <Trans>{t("trusted_community.host_verified")}</Trans>
              </p>
              <p>{t("trusted_community.community_part")}</p>
              <Link
                to={
                  getToken()
                    ? "/" +
                      t("results", { ns: "routes" }) +
                      "?destinationId=0&state=&latitud=20.6595382&longitud=-103.3494376&dateInitial=09%2F08%2F2023&hourInitial=12%3A00&dateEnd=12%2F08%2F2023&hourEnd=12%3A00&experiences=000000000&type=012345&transmission=01"
                    : "/" + t("signup", { ns: "routes" })
                }
                className="btn btn-orange"
              >
                {t("transparency_information.rent_car")}
              </Link>
            </>
          }
          image={
            <img
              src={h_homepageImage.securityAndTrust}
              className="w-100"
              alt="a person checking a list"
            />
          }
          componentColor={""}
          componentOrder={"order-bottom-right"}
        />

        <div className="home-carousel">
          <CarSuggestions
            componentColor={""}
            title={t("carousel.title")}
            arrowColor={"white"}
            titlePosition={"center"}
          />
        </div>

        <SplitInformation
          title={
            <h3 data-testid="earn-money-title">{t("earn_money.title")}</h3>
          }
          subtitle={
            <h4 data-testid="earn-money-subtitle">
              {t("earn_money.share_your_car")}
            </h4>
          }
          content={
            <>
              <p>{t("earn_money.new_way_to_rent")}</p>
              <p>{t("earn_money.new_way_to_rent_extra")}</p>
              <Link
                to={"/" + t("host", { ns: "routes" })}
                className="btn btn-orange"
              >
                {t("earn_money.start_now")}
              </Link>
            </>
          }
          image={
            <img
              src={h_homepageImage.earnMoney}
              id="un_wallet"
              className="w-100"
              alt="a person happy behind a giant wallet"
            />
          }
          componentColor={""}
          componentOrder={"order-bottom-left"}
        />
      </article>
    </>
  );
};
