import { FC } from "react";
import orderImages from "../../assets/images/order/index";
import authenticationImages from "../../assets/images/authentication/index";
import "./CompleteProfile.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Toast from "../../components/toast/component";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useCompleteProfile } from "../../hooks/profile/useCompleteProfile";
import Loading from "../../components/loading/component";

export const CompleteProfile: FC = () => {
  const { t, formikCompleteProfile, openAlert, setOpenAlert, errorMsg } =
    useCompleteProfile();

  const styles = {
    completeProfileButton: {
      backgroundColor:
        formikCompleteProfile.isValid && formikCompleteProfile.dirty
          ? "#f76f8e"
          : "#6D6D6D",
    },
  };
  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/completar-perfil"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/completar-perfil"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/completar-perfil"
        />
      </Helmet>
      <div id="login">
        <Toast open={openAlert} setOpen={setOpenAlert} type="error">
          {errorMsg}
        </Toast>
        <article className="complete-profile-container">
          <div className="join-container">
            <div className="title-container">
              <p data-testid="completeProfile-slogan">{t("slogan")}</p>
            </div>
            <img
              className="complete-img"
              src={authenticationImages.completeProfile}
              alt=""
            />
          </div>
          <div className="signup-form" id="signup_form">
            <form>
              <div className="input-form">
                <label data-testid="name-label">
                  {t("complete_profile_form.name.label")}
                </label>
                <input
                  name="name"
                  id="txtName"
                  type="text"
                  data-testid="name-input"
                  placeholder={t("complete_profile_form.name.placeholder")}
                  onChange={formikCompleteProfile.handleChange("name")}
                  onBlur={formikCompleteProfile.handleBlur("name")}
                  value={formikCompleteProfile.values.name}
                />
                <p className="alert-text">
                  {t("complete_profile_form.name.alert")}
                </p>
                <p className="alert-text">
                  {formikCompleteProfile.touched.name
                    ? formikCompleteProfile.errors.name
                    : ""}
                </p>
              </div>
              <div className="input-form">
                <label data-testid="surname-label">
                  {t("complete_profile_form.surname.label")}
                </label>
                <input
                  name="surName"
                  id="txtsurName"
                  type="text"
                  data-testid="surname-input"
                  placeholder={t("complete_profile_form.surname.placeholder")}
                  onChange={formikCompleteProfile.handleChange("surName")}
                  onBlur={formikCompleteProfile.handleBlur("surName")}
                  value={formikCompleteProfile.values.surName}
                />
                <p className="alert-text">
                  {t("complete_profile_form.surname.alert")}
                </p>
                <p className="alert-text">
                  {formikCompleteProfile.touched.surName
                    ? formikCompleteProfile.errors.surName
                    : ""}
                </p>
              </div>
              <div className="number-form">
                <label data-testid="phone-label">
                  {t("complete_profile_form.phone")}
                </label>
                <PhoneInput
                  country={"mx"}
                  specialLabel=""
                  onChange={formikCompleteProfile.handleChange("phone")}
                  onBlur={formikCompleteProfile.handleBlur("phone")}
                  value={formikCompleteProfile.values.phone}
                />
                <p className="alert-text">
                  {formikCompleteProfile.touched.phone
                    ? formikCompleteProfile.errors.phone
                    : ""}
                </p>
              </div>
              <div className="checkbox-form">
                <img
                  id="firstCheck"
                  style={{ boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.5)" }}
                  src={
                    formikCompleteProfile.values.privacyPolicies
                      ? orderImages.boxCheck
                      : orderImages.boxEmpty
                  }
                  onClick={() =>
                    formikCompleteProfile.setFieldValue(
                      "privacyPolicies",
                      !formikCompleteProfile.values.privacyPolicies
                    )
                  }
                  alt="checkbox"
                />
                <p className="option">
                  {t("complete_profile_form.privacy_policies")}
                  <Link
                    to={
                      "https://upcdn.io/12a1xrm/raw/balety.com/documents/aviso-de-privacidad.pdf"
                    }
                    target="_blank"
                    data-testid="privacyPolicies-checkbox"
                  >
                    {t("complete_profile_form.privacy_policies_extra")}
                  </Link>
                </p>
              </div>
              {formikCompleteProfile.values.privacyPolicies}
              <div className="checkbox-form">
                <img
                  id="secondCheck"
                  style={{ boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.5)" }}
                  src={
                    formikCompleteProfile.values.termsAndConditions
                      ? orderImages.boxCheck
                      : orderImages.boxEmpty
                  }
                  onClick={() =>
                    formikCompleteProfile.setFieldValue(
                      "termsAndConditions",
                      !formikCompleteProfile.values.termsAndConditions
                    )
                  }
                  alt="checkbox"
                />
                <p className="option">
                  {t("complete_profile_form.terms_and_conditions")}
                  <Link
                    to={
                      "https://upcdn.io/12a1xrm/raw/balety.com/documents/terminos-y-condiciones-de-uso.pdf"
                    }
                    target="_blank"
                    data-testid="termsAndConditions-checkbox"
                  >
                    {t("complete_profile_form.terms_and_conditions_extra")}
                  </Link>
                </p>
              </div>
              <div className="button-form">
                <button
                  type="button"
                  id="complete_profile"
                  data-testid="completeProfile-button"
                  className="btn btn-orange"
                  disabled={formikCompleteProfile.isSubmitting}
                  onClick={() => {
                    if (!formikCompleteProfile.isSubmitting)
                      formikCompleteProfile.handleSubmit();
                  }}
                  style={styles.completeProfileButton}
                >
                  {!formikCompleteProfile.isSubmitting ? (
                    t("complete_profile_form.complete_registration")
                  ) : (
                    <Loading height="1vh" color="#fff" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </article>
      </div>
    </>
  );
};
