import { Fragment } from "react";
import "./perks.scss";

export const Perks = ({
  title,
  perkList,
}: {
  title: JSX.Element;
  perkList: JSX.Element[];
}) => {
  return (
    <article className="benefits-container-unhomepage">
      {title}
      <div className="list-benefits">
        {perkList.map((perk, index) => (
          <Fragment key={index}>{perk}</Fragment>
        ))}
      </div>
    </article>
  );
};
