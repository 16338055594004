import "./styles.scss";
import processingImage from "../../../assets/images/help/processing_payment.png";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

// Component created to collect analytics data
const CheckoutSuccessful = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation(["routes", "driverReservation"]);

  // Google Analytics
  useEffect(() => {
    if (search) {
      navigate(
        "/" + t("drivers") + "/" + t("trips") + "/" + search.get("checkout_id")
      );
    }
  }, [search]);

  return (
    <article className="processing-payment-container">
      <h1 className="processing-payment-title">
        {t("driverReservation:success.payment_in_process")}
      </h1>
      <p className="processing-payment-info">
        {t("driverReservation:success.wait_warning")}
      </p>
      <img className="processing-payment-img" src={processingImage} />
    </article>
  );
};

export default CheckoutSuccessful;
