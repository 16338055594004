import { useTranslation } from "react-i18next";
import { MenuItem, Select, TextField } from "@mui/material";
import { RefObject, useEffect, useRef, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { useFormik } from "formik";
import { homeFilter, homeFilterSchema } from "./form";
import {
  findLatitudLongitud,
  findState,
  handleAutocompleteInput,
} from "../../lib/helpers/mapsActions";
import { handleClickOutside } from "../../lib/helpers/dropdownBoxActions";
import { useHours } from "../../hooks/hours";
import { useTopFilter } from "../../hooks/filter";
import orderImages from "../../assets/images/order";
import { getDestinations } from "../../services/car";
import { useQuery } from "react-query";
import { DestinationsDropdown } from "../../lib/components/resultsTools/destinationsDropdown/component";
import { DestinationContext } from "../../lib/components/resultsTools/destinationsDropdown/types";
import { currentLocation, Destination } from "../../models/destination/types";
import {
  mockCurrentLocation,
  mockDestination,
} from "../../models/destination/mockups";
import { InputAutocomplete } from "../../lib/components/inputAutocomplete/components";
import { useAutocompleteFilter } from "../../hooks/nav/filters/autocompleteFilter";
import { useDestination } from "../../hooks/destination";
import { useBlockNextHalfHours } from "../../hooks/car/show/blockNextHalfHours";
import { EditDateBlock } from "../../lib/components/carTools/editCarPrice/types";
import { greenwichToMilitarTime } from "../../lib/helpers/timeHandler";
import { useWindowSize } from "../../hooks/windowSize";

export type locationType = "current" | "destination" | "";

export const NavHomeFilter = () => {
  const mockInitialDate = dayjs().add(2, "day");
  const mockEndDate = dayjs().add(5, "day");
  const [dates, setDates] = useState<DateRange<Dayjs>>([
    mockInitialDate,
    mockEndDate,
  ]);
  const [autocomplete, setAutocompleteInput] = useState("");
  const filter = useTopFilter();
  const { t } = useTranslation(["home", "routes"]);
  const [dropdownAction, setDropdownAction] = useState<boolean>(false);
  const destinationRef = useRef<HTMLInputElement>(null);
  const { formatHours } = useHours(30);
  const { data: destinations } = useQuery(["destinations"], getDestinations);
  const [blockDates, setBlockDates] = useState<EditDateBlock[]>([]);
  const [finalLeftHours, setFinalLeftHours] = useState<string[]>(formatHours);
  const [finalRightHours, setFinalRightHours] = useState<string[]>(formatHours);
  const windowSize = useWindowSize();

  const [destinationSelected, setDestinationSelected] =
    useState<Destination>(mockDestination);

  const [type, setType] = useState<locationType>("destination");
  const [currentLocation, setCurrentLocation] =
    useState<currentLocation>(mockCurrentLocation);

  useEffect(() => {
    document.addEventListener(
      "click",
      (event) => {
        if (handleClickOutside(destinationRef, event)) setDropdownAction(false);
      },
      true
    );
  }, [destinationRef.current]);

  useEffect(() => {
    if (formatHours) {
      formikHomeFilter.setFieldValue("hourInitial", "12:00");
      formikHomeFilter.setFieldValue("hourEnd", "12:00");
    }
  }, [formatHours]);

  const formikHomeFilter = useFormik<homeFilter>({
    initialValues: {
      destinationId: 1,
      state: "",
      commercialName: "",
      location: { latitud: 20.6595382, longitud: -103.3494376 },
      hourInitial: "",
      dateInitial: mockInitialDate,
      hourEnd: "",
      dateEnd: mockEndDate,
    },
    validationSchema: homeFilterSchema,
    onSubmit: filter,
  });

  const { handleAutocomplete, handleClick } = useAutocompleteFilter({
    dropdownAction,
    currentLocation,
    type,
    destinationSelected,
    autocomplete,
    setAutocompleteInput,
    setType,
    setCurrentLocation,
    setDropdownAction,
    setFormikValue: formikHomeFilter.setFieldValue,
  });

  useBlockNextHalfHours({
    componentName: "navHomeFilter",
    formatHours,
    setBlockDates,
    pickUpDate: formikHomeFilter.values.dateInitial,
    returnDate: formikHomeFilter.values.dateEnd,
    pickUpTime: formikHomeFilter.values.hourInitial,
    setFinalLeftHours,
    setFinalRightHours,
    setFields: formikHomeFilter.setFieldValue,
  });

  const formatDates = (newValue: DateRange<Dayjs>) => {
    setDates(newValue);
    if (!newValue[0] || !newValue[1]) return;

    const startDate = dayjs(newValue[0]);
    const endDate = dayjs(newValue[1]);

    formikHomeFilter.setFieldValue("dateInitial", startDate);
    formikHomeFilter.setFieldValue("dateEnd", endDate);
  };

  useDestination({
    location: formikHomeFilter.values.location,
    destinations,
    setFieldValue: formikHomeFilter.setFieldValue,
  });

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS,
    onPlaceSelected: (place) => {
      formikHomeFilter.setFieldValue("location", findLatitudLongitud(place));
      formikHomeFilter.setFieldValue(
        "commercialName",
        handleAutocompleteInput(place)
      );
      formikHomeFilter.setFieldValue(
        "state",
        findState(place.address_components)
      );
      setAutocompleteInput(handleAutocompleteInput(place));
    },
    options: {
      componentRestrictions: { country: "mx" },
      fields: [
        "geometry",
        "formatted_address",
        "place_id",
        "address_components",
        "name",
      ],
      types: [],
    },
    language: "es-419",
  });

  const styles = {
    homeFilterButton: {
      backgroundColor:
        formikHomeFilter.isValid && formikHomeFilter.dirty
          ? "#f76f8e"
          : "#828282",
    },
  };

  return (
    <div className="card un-form">
      <div className="card-body">
        <div className="where-container">
          <label data-testid="where-label">{t("searchBar.where.title")}</label>
          <div>
            <InputAutocomplete
              ref={ref as RefObject<null>}
              handleClick={handleClick}
              handleAutocomplete={handleAutocomplete}
              autocomplete={autocomplete}
            />
            {formikHomeFilter.touched.state
              ? formikHomeFilter.errors.state
              : ""}
          </div>
          {dropdownAction && (
            <DestinationContext.Provider
              value={{
                setDropdownAction,
                setDestinationSelected,
                destinations: destinations || [mockDestination],
                setType,
                setCurrentLocation,
              }}
            >
              <DestinationsDropdown ref={destinationRef} />
            </DestinationContext.Provider>
          )}
        </div>
        <div className="un-container-date">
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "", end: "" }}
          >
            <DateRangePicker
              className="date"
              value={dates}
              inputFormat="DD/MM/YYYY"
              disablePast
              shouldDisableDate={(date) =>
                blockDates.some((dateList) =>
                  dateList.dates.some(
                    (dateItem) =>
                      dayjs(date).format("DD-MM-YYYY") ===
                      dayjs(dateItem).format("DD-MM-YYYY")
                  )
                )
              }
              onChange={(newValue) => {
                formatDates(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <div className="dates">
                  <div className="un-desde">
                    <label className="w-100" data-testid="since-label">
                      {t("searchBar.since")}
                    </label>
                    <div className="d-flex align-items-center">
                      <TextField {...startProps} className="datepicker" />

                      {finalLeftHours.length !== 0 && (
                        <Select
                          labelId="time-left"
                          id="time-left"
                          label=""
                          defaultValue=""
                          value={formikHomeFilter.values.hourInitial}
                          onChange={(event) =>
                            formikHomeFilter.setFieldValue(
                              "hourInitial",
                              event.target.value
                            )
                          }
                          className="dropdown"
                        >
                          {finalLeftHours.map((hour) => (
                            <MenuItem
                              key={greenwichToMilitarTime(hour)}
                              value={greenwichToMilitarTime(hour)}
                            >
                              {hour}
                              {hour === "12:00 AM" && (
                                <img
                                  src={orderImages.moon}
                                  className="time-icon"
                                />
                              )}
                              {hour === "12:00 PM" && (
                                <img
                                  src={orderImages.sun}
                                  className="time-icon"
                                />
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </div>
                    {formikHomeFilter.touched.hourInitial
                      ? formikHomeFilter.errors.hourInitial
                      : ""}
                  </div>
                  <div className="un-hasta">
                    <label data-testid="until-label">
                      {t("searchBar.until")}
                    </label>
                    <div className="d-flex align-items-center">
                      <TextField {...endProps} className="datepicker" />
                      {finalRightHours.length !== 0 && (
                        <Select
                          labelId="time-right"
                          id="time-right"
                          label=""
                          defaultValue=""
                          value={formikHomeFilter.values.hourEnd}
                          onChange={(event) =>
                            formikHomeFilter.setFieldValue(
                              "hourEnd",
                              event.target.value
                            )
                          }
                          className="dropdown"
                        >
                          {finalRightHours.map((hour) => (
                            <MenuItem
                              key={greenwichToMilitarTime(hour)}
                              value={greenwichToMilitarTime(hour)}
                            >
                              {hour}
                              {hour === "12:00 AM" && (
                                <img
                                  src={orderImages.moon}
                                  className="time-icon"
                                />
                              )}
                              {hour === "12:00 PM" && (
                                <img
                                  src={orderImages.sun}
                                  className="time-icon"
                                />
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </div>
                  </div>
                </div>
              )}
            />
          </LocalizationProvider>
        </div>
        <button
          type="button"
          style={styles.homeFilterButton}
          aria-label="search-cars"
          disabled={!(formikHomeFilter.isValid && formikHomeFilter.dirty)}
          onClick={() => formikHomeFilter.handleSubmit()}
          className="btn btn-orange"
        >
          {windowSize < 990 ? (
            t("searchBar.search")
          ) : (
            <img src={orderImages.search} alt="" />
          )}
        </button>
      </div>
    </div>
  );
};
