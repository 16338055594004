import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Deactivated } from "../../../models/car/types";
import { getCarsByUser } from "../../../services/car";
import { TranslationContext } from "../../driverReservation/show/component";
import "./styles.scss";
import images from "../../../assets/images/a_cars/index";
import {
  CarsCard,
  CarsCardMobile,
} from "../../../components/carCards/component";
import helpImages from "../../../assets/images/help/index";
import Loading from "../../../components/loading/component";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { ProtectGPSBanner } from "../../../lib/components/carTools/banners/protectGPSBanner/ProtectGPSBanner";
import { ListTipsBanner } from "../../../lib/components/carTools/banners/listTipsBanner/ListTipsBanner";

export const CarIndex = () => {
  const { data: cars, isLoading } = useQuery(["cars"], () => getCarsByUser());
  const { t } = useTranslation(["car", "routes"]);

  return (
    <>
      <Helmet>
        <title>{t("seo.index.title")}</title>
        <meta name="description" content={t("seo.index.description")} />
        <meta name="robots" content="noindex,follow" />
        <link rel="alternate" hrefLang="es" href="https://romio.mx/mis-autos" />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/mis-autos"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/mis-autos"
        />
      </Helmet>
      <TranslationContext.Provider value={t}>
        <article className="a-cars-container">
          <Swiper
            slidesPerView={1}
            loop
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="banner-swipper"
          >
            <SwiperSlide>
              <ListTipsBanner />
            </SwiperSlide>
            <SwiperSlide>
              <ProtectGPSBanner />
            </SwiperSlide>
          </Swiper>
          <div className="join-container">
            <div className="title-container">
              <h1>{t("index.my_cars")}</h1>
            </div>
          </div>
          {(!cars || cars?.length === 0) && !isLoading && (
            <div className="cars-empty-list">
              <img src={helpImages.anfitrionRegular} />
              <p>{t("index.empty_view")}</p>
            </div>
          )}
          <div className="btn-container">
            <Link
              to={"/" + t("upload_car", { ns: "routes" })}
              className="btn btn-add"
            >
              <img src={images.plus} alt="plus" aria-hidden="true" />
              <p>{t("index.add_car")}</p>
            </Link>
          </div>
          <div className="reservation-card">
            <div className="car-card">
              <ul className="list-group list-group-flush">
                {isLoading ? (
                  <Loading height="50vh" />
                ) : (
                  cars?.map((car) => (
                    <React.Fragment key={car.id}>
                      {car.isReadyByHost !== Deactivated.REMOVED &&
                      !car.plate.includes("Eliminado-") ? (
                        <CarsCardMobile {...car} />
                      ) : null}
                    </React.Fragment>
                  ))
                )}
              </ul>
            </div>
          </div>
        </article>
        <article className="a-cars-container-des">
          <div className="join-container">
            <div className="d-flex justify-content-center w-100">
              <Swiper
                slidesPerView={1}
                loop
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 7000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="banner-swipper"
              >
                <SwiperSlide>
                  <ListTipsBanner />
                </SwiperSlide>
                <SwiperSlide>
                  <ProtectGPSBanner />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="title-container d-flex justify-content-between">
              <h1 data-testid="my-cars-title">{t("index.my_cars")}</h1>
              {cars && cars?.length > 0 && (
                <div id="add_button_des" className="btn-container">
                  <Link
                    to={"/" + t("upload_car", { ns: "routes" })}
                    data-testid="add-button"
                    className="btn btn-add"
                  >
                    <img src={images.plus} alt="plus" aria-hidden="true" />
                    <p>{t("index.add_car")}</p>
                  </Link>
                </div>
              )}
            </div>
          </div>
          {(!cars || cars?.length === 0) && !isLoading && (
            <div className="cars-empty-list">
              <img src={helpImages.anfitrionRegular} aria-hidden="true" />
              <p>{t("index.empty_view")}</p>
              <div className="btn-container">
                <Link
                  to={"/" + t("upload_car", { ns: "routes" })}
                  data-testid="add-button"
                  className="btn btn-add"
                >
                  <img src={images.plus} alt="plus" aria-hidden="true" />
                  <p>{t("index.add_car")}</p>
                </Link>
              </div>
            </div>
          )}
          <div className="reservation-card">
            <div className="car-card">
              <ul
                className="list-group list-group-flush"
                data-testid="car-list"
              >
                {isLoading ? (
                  <Loading height="60vh" />
                ) : (
                  cars?.map((car) => (
                    <React.Fragment key={car.id}>
                      {car.isReadyByHost != Deactivated.REMOVED &&
                      !car.plate.includes("Eliminado-") ? (
                        <CarsCard {...car} />
                      ) : null}
                    </React.Fragment>
                  ))
                )}
              </ul>
            </div>
          </div>
        </article>
      </TranslationContext.Provider>
    </>
  );
};
