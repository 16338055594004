import aCheck from "./a_check.svg";
import arrow from "./arrow.svg";
import arrowLeft from "./arrow_left.svg";
import arrowRight from "./arrow_right.svg";
import calendar from "./calendar.svg";
import comment from "./comment.svg";
import heart from "./heart.svg";
import help from "./help.svg";
import hostEclipse from "./host_status.svg";
import logo from "./balety_logo.png";
import burger from "./burger.svg";
import burgerWhite from "./burger_white.svg";
import downArrow from "./down_arrow.svg";
import cross from "./cross.svg";
import crossOrange from "./cross_orange.svg";
import key from "./key.svg";
import login from "./login.svg";
import loginUser from "./login_user.svg";
import logoOrange from "./logo_orange.png";
import logout from "./logout.svg";
import messages from "./messages.svg";
import money from "./money.svg";
import myCars from "./my_cars.svg";
import oMessages from "./o_messages.svg";
import oLogin from "./o_login.svg";
import signup from "./signup.svg";
import triangle from "./triangle.svg";
import logoHorizontalWhite from "./logo_horizontal_white.svg";
import logoDegradedOrange from "./logo_horizontal_degraded.svg";
import minLogo from "./min_logo_white.svg";
import minLogoOrange from "./min_logo_orange.svg";
import progress from "./progress.svg";
import logoRomioBlack from "./logo_romio_black.svg";
import burgerBlack from "./burger_black.svg";
import crossBlack from "./cross_black.svg";
import bMessages from "./b_messages.svg";
import bLogin from "./b_login.svg";
import romioIcon from "./romio_icon.svg";

export default {
  aCheck,
  arrow,
  arrowLeft,
  arrowRight,
  logo,
  burger,
  burgerWhite,
  downArrow,
  cross,
  crossOrange,
  calendar,
  comment,
  heart,
  help,
  hostEclipse,
  key,
  login,
  loginUser,
  logoOrange,
  logout,
  messages,
  oLogin,
  oMessages,
  minLogo,
  minLogoOrange,
  money,
  myCars,
  triangle,
  signup,
  logoHorizontalWhite,
  logoDegradedOrange,
  progress,
  logoRomioBlack,
  burgerBlack,
  crossBlack,
  bMessages,
  bLogin,
  romioIcon,
};
