import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { getAddressesByCarID } from "../../../services/address";
import { getCar } from "../../../services/car";
import { getUser, getUserByID } from "../../../services/user";
import { useEffect, useState } from "react";
import { decryptId } from "../../../lib/helpers/encrypt";

export const useShowCar = () => {
  const { t } = useTranslation(["car"]);
  const [search] = useSearchParams();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [preview, setPreview] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const { carId: id } = useParams();
  const carId = decryptId(id || "");

  const { data: carData } = useQuery(["car"], () => getCar(carId));
  const { data: addressesData } = useQuery(
    ["addresses", carData?.id],
    () => getAddressesByCarID(carData?.id),
    {
      enabled: !!carData,
    }
  );
  const { data: userData } = useQuery(
    ["host", carData?.userId],
    () => getUserByID(carData?.userId),
    {
      enabled: !!carData,
    }
  );

  const { data: userLogged } = useQuery(["userLogged"], () => getUser());

  useEffect(() => {
    // It will show a preview just if has a preview param
    const preview = search.get("preview") === "";

    if (!userLogged || !userData) return;

    if (userData.id !== userLogged.id) {
      setPreview(false);
      return;
    }

    setPreview(preview);
  }, [search, userLogged, userData]);

  return {
    t,
    carData,
    userData,
    addressesData,
    search,
    openAlert,
    setOpenAlert,
    errorMsg,
    setErrorMsg,
    preview,
    id,
  };
};
