import { FormikHelpers } from "formik";
import { Dispatch, SetStateAction, createContext } from "react";
import { Auth } from "../../models/auth/types";
import { User } from "../../models/user/types";

export interface NoAuthNavbarProps {
  show: boolean;
  burgerShow: () => void;
}

export interface AuthNavbarProps extends NoAuthNavbarProps {
  routeType: string;
  user: User;
}

export interface AuthActions {
  fetchLogin: (
    values: Auth,
    formikHelpers: FormikHelpers<Auth>
  ) => void | Promise<void>;
  logout: () => void;
  urlType: string;
  errorMsg: string | null;
}

export interface LoginActions {
  isLogged: boolean;
  setIsLogged: Dispatch<SetStateAction<boolean>>;
  isComplete: boolean;
  setIsComplete: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthActions>({
  fetchLogin: async (e) => {
    e;
  },
  logout: () => {
    //do nothing
  },
  urlType: "",
  errorMsg: null,
});

export interface NavbarMenuProps {
  user: User | undefined;
  routeType: string;
  logged: boolean;
}
export interface AuthNavbarMenuProps {
  user: User | undefined;
  routeType: string;
}

export interface AuthCollapseProps {
  routeType: string;
  user: User | undefined;
}

export interface NavbarCollapseProps {
  user: User | undefined;
  burgerMenuShow: boolean;
  routeType: string;
  logged: boolean;
  actionClick: () => void;
}

export interface LoginCollapseProps {
  show: boolean;
  showLogin: () => void;
}
