import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import a_carsImages from "../../../assets/images/a_cars";
import h_homepageImages from "../../../assets/images/home/h_homepage";
import navbarImages from "../../../assets/images/navbar";
import EditCarDetails from "../../../lib/components/carTools/editCarDetails/EditCarDetails";
import { EditCarDocumentation } from "../../../lib/components/carTools/editCarDocumentation/EditCarDocumentation";
import { EditCarModel } from "../../../lib/components/carTools/editCarModel/EditCarModel";
import { EditCarPrice } from "../../../lib/components/carTools/editCarPrice/EditCarPrice";
import { TranslationContext } from "../../driverReservation/show/component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./EditCar.scss";
import { encryptId } from "../../../lib/helpers/encrypt";
import { Helmet } from "react-helmet";
import { carShortInformation } from "../../../lib/helpers/textHandler";
import { useQuery } from "react-query";
import { getCar } from "../../../services/car";
import {
  TabPanel,
  TabProps,
} from "../../../lib/components/editCarTools/tabPanel/TabPanel";
import { getInsuranceAndRefresh } from "../../../services/insurance";

export const EditCar = () => {
  const { carId } = useParams();
  const [value, setValue] = useState(0);
  const { t } = useTranslation(["car", "routes"]);
  const [navEdit, setNavEdit] = useState<string>("Datos principales");
  const { data: car } = useQuery(["car", carId], () => getCar(carId), {
    enabled: !!carId,
  });
  useQuery(
    ["InsuranceCar", carId],
    () => getInsuranceAndRefresh(parseInt(carId || "0")),
    {
      enabled: !!carId,
    }
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const steps = [
    t("edit.model_and_ubication.title"),
    t("edit.details.title"),
    t("edit.price_disponibility.title"),
    t("edit.documentation.title"),
  ];

  return (
    <>
      <Helmet>
        <title>
          {car && t("seo.edit.title", { car: carShortInformation(car) })}
        </title>
        <meta name="description" content={t("seo.edit.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href={`https://romio.mx/editar-mi-auto/${carId}`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://romio.mx/en/editar-mi-auto/${carId}`}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://romio.mx/editar-mi-auto/${carId}`}
        />
      </Helmet>
      <TranslationContext.Provider value={t}>
        <article className="edit-car-container">
          <Accordion className="back-menu">
            <AccordionSummary
              className="nav-join"
              expandIcon={<ExpandMoreIcon />}
            >
              <h2 id="page_title">{navEdit}</h2>
            </AccordionSummary>
            <AccordionDetails className="nav-filter">
              <div className="un-form">
                <div className="card-body">
                  <div className="option-container">
                    <>
                      {steps.map(
                        (value: string, index: number) =>
                          navEdit != value && (
                            <div className="option" key={index}>
                              <a
                                id="details_stage"
                                onClick={() => {
                                  setValue(index);
                                  setNavEdit(value);
                                }}
                              >
                                {value}
                              </a>
                              <div className="dropdown-divider"></div>
                            </div>
                          )
                      )}
                    </>
                  </div>
                  <Link
                    to={{
                      pathname:
                        "/" +
                        t("details", { ns: "routes" }) +
                        "/" +
                        encryptId(carId || "0"),
                      search: "?preview",
                    }}
                    target="_blank"
                    className="btn btn-orange"
                  >
                    {t("edit.preview")}
                  </Link>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="nav-join" style={{ display: "none" }}>
            <h2 id="page_title">{t("edit.model_and_ubication.title")}</h2>
            <img id="drop_arrow" src={navbarImages.downArrow} alt="" />
          </div>
          <nav
            className="nav-filter"
            id="nav_filter"
            style={{ display: "none" }}
          >
            <div className="card un-form">
              <div className="card-body">
                <div className="option-container">
                  <div className="option">
                    <div className="close-container">
                      <a>{t("edit.model_and_ubication.title")}</a>
                      <img
                        className="drop_arrow"
                        id="drop_arrow"
                        src={h_homepageImages.upArrow}
                        alt=""
                      />
                    </div>
                    <div className="dropdown-divider"></div>
                  </div>
                  <div className="option">
                    <a id="details_stage">{t("edit.details.title")}</a>
                    <div className="dropdown-divider"></div>
                  </div>
                  <div className="option">
                    <a id="price_stage">
                      {t("edit.price_disponibility.title")}
                    </a>
                    <div className="dropdown-divider"></div>
                  </div>
                </div>
                <Link
                  to={t("preview", { ns: "routes" })}
                  className="btn btn-orange"
                >
                  {t("edit.preview")}
                </Link>
              </div>
            </div>
          </nav>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="back-menu-des"
          >
            <Box className="nav-filter">
              <Tabs
                value={value}
                onChange={handleChange}
                className="option-container"
                aria-label="basic tabs example"
              >
                <Tab
                  className="option"
                  label={t("edit.model_and_ubication.title")}
                  {...TabProps(0)}
                />
                <Tab
                  className="option"
                  data-testid="details-step"
                  label={t("edit.details.title")}
                  {...TabProps(1)}
                />
                <Tab
                  className="option"
                  data-testid="price-disponibility-step"
                  label={t("edit.price_disponibility.title")}
                  {...TabProps(2)}
                />
                <Tab
                  className="option"
                  data-testid="documentation-step"
                  label={t("edit.documentation.title")}
                  {...TabProps(3)}
                />
              </Tabs>
              <div className="btn-container-preview">
                <Link
                  to={{
                    pathname:
                      "/" +
                      t("details", { ns: "routes" }) +
                      "/" +
                      encryptId(carId || "0"),
                    search: "?preview",
                  }}
                  target="_blank"
                  className="btn btn-orange"
                >
                  {t("edit.preview")}
                </Link>
              </div>
            </Box>
          </Box>
          <Link
            to={"/" + t("my_cars", { ns: "routes" })}
            className="return-prev-page text-decoration-none"
          >
            <img className="prev-arrow" src={a_carsImages.returnArrow} alt="" />
            <p>
              <Trans>{t("edit.return")}</Trans>
            </p>
          </Link>
          <TabPanel value={value} index={0}>
            <EditCarModel />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EditCarDocumentation />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EditCarPrice />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <EditCarDetails />
          </TabPanel>
        </article>
      </TranslationContext.Provider>
    </>
  );
};
