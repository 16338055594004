import { Checkbox, FormControlLabel, TextField, Tooltip } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import a_carsImages from "../../../../../assets/images/a_cars";
import carsImages from "../../../../../assets/images/car";
import { ExtraAddressProps } from "../../../../../views/car/add/types";
import Autocomplete from "react-google-autocomplete";
import { ModalMap } from "../../../../../components/modals/map/component";
import { useExtraAddress } from "./useExtraAddress";
import { NumericFormat } from "react-number-format";
import { handleAutocompleteInput } from "../../../../helpers/mapsActions";

export const ExtraAddress: FC<ExtraAddressProps> = ({ index }) => {
  const autocompleteInput = useRef<HTMLInputElement | null>(null);
  const {
    t,
    handleDeleteAddress,
    handleExtraAddress,
    handleExtraAddressPrice,
    deliveryCost,
    handleDeliveryCost,
    isMapOpen,
    setMapOpen,
    values,
  } = useExtraAddress({
    index,
  });

  if (!values.extraAddresses) {
    return <></>;
  }

  useEffect(() => {
    if (
      values.extraAddresses[index].address &&
      values.extraAddresses[index].address.formatted_address &&
      autocompleteInput.current
    ) {
      autocompleteInput.current.value = handleAutocompleteInput(
        values.extraAddresses[index].address
      );
    }
  }, [values.extraAddresses[index]]);

  return (
    <>
      <Autocomplete
        className="option-input"
        apiKey={process.env.REACT_APP_GOOGLE_MAPS}
        language="es-419"
        ref={autocompleteInput}
        data-testid="extra-address"
        options={{
          componentRestrictions: { country: "mx" },
          fields: [
            "geometry",
            "formatted_address",
            "place_id",
            "address_components",
            "name",
          ],
          types: [],
        }}
        onPlaceSelected={(place) => {
          handleExtraAddress(place);
        }}
      />
      <div className="btn-container">
        <button
          type="button"
          className="btn btn-orange-des"
          onClick={() => setMapOpen((prev) => ({ ...prev, status: true }))}
          disabled={
            !values.extraAddresses[index]
              ? true
              : !values.extraAddresses[index].address
          }
        >
          <img
            className="ubication-icon"
            src={carsImages.map}
            alt="open map modal"
          />
          <p>{t("add.car_price.delivery_return.map")}</p>
        </button>
        <button
          type="button"
          id="erase_direction"
          onClick={() => handleDeleteAddress(index)}
          className="btn btn-erase"
        >
          <img src={a_carsImages.erase} alt="erase address" />
        </button>
      </div>
      <div className="add-price">
        <span>
          {t("add.car_price.delivery_return.add_price")}
          <Tooltip title={t("add.car_price.tooltips.add_price")}>
            <img src={a_carsImages.info} alt="info" />
          </Tooltip>
        </span>
        <div className="delibery-price">
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={2}
            customInput={TextField}
            data-testid="delibery-input"
            className="delibery-input"
            value={values.extraAddresses[index].price ?? "0"}
            onChange={(e) => handleExtraAddressPrice(e.target.value, index)}
            disabled={deliveryCost}
          />
          <div className="enable-check">
            <FormControlLabel
              control={
                <Checkbox
                  checked={deliveryCost}
                  color="aqua"
                  onChange={(e) => handleDeliveryCost(e, index)}
                />
              }
              data-testid="extra-address-cost"
              label={t("add.car_price.delivery_return.no_charge")}
            />
          </div>
        </div>
      </div>
      <ModalMap
        lat={
          values.extraAddresses[index]
            ? parseFloat(
                values.extraAddresses[
                  index
                ].address?.geometry.location.lat.toString()
              )
            : 0
        }
        lng={
          values.extraAddresses[index]
            ? parseFloat(
                values.extraAddresses[
                  index
                ].address?.geometry.location.lng.toString()
              )
            : 0
        }
        completeAddress={
          values.extraAddresses[index]
            ? values.extraAddresses[index].address?.formatted_address
            : ""
        }
        isOpen={isMapOpen.status}
        setOpen={() => setMapOpen((prev) => ({ ...prev, status: false }))}
      />
    </>
  );
};
