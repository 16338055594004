import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ImgHTMLAttributes,
} from "react";
import "./testimony.scss";

export const Testimony = ({
  testimonies,
  componentColor,
}: {
  testimonies: CardTestimonyProps[];
  componentColor: string;
}) => {
  return (
    <section
      className="testimony-container"
      style={{ backgroundColor: componentColor }}
    >
      {testimonies.map((testimony, index) => (
        <CardTestimony {...testimony} key={index} />
      ))}
    </section>
  );
};

export interface CardTestimonyProps {
  image: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  name: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >;
  testimony: DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLHeadingElement
  >;
}

export const CardTestimony: FC<CardTestimonyProps> = ({
  image,
  name,
  testimony,
}) => {
  return (
    <div className="testimony-card">
      <>
        {image}
        {name}
        {testimony}
      </>
    </div>
  );
};
