import { Fragment } from "react";
import "./steps.scss";

interface StepsProps {
  title?: JSX.Element;
  image: JSX.Element;
  stepList: JSX.Element[];
}

export const Steps = ({ title, image, stepList }: StepsProps) => {
  return (
    <article className="un-steps">
      {title}
      <div className="information-container">
        <div className="top-container">{image}</div>
        <div className="bottom-container">
          <div className="stepper">
            {stepList.map((step, index) => (
              <Fragment key={index}>{step}</Fragment>
            ))}
          </div>
        </div>
      </div>
    </article>
  );
};
