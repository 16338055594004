import { FC, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalProps } from "../types";
import navbarImages from "../../../assets/images/navbar";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { TripContext } from "../../../views/driverReservation/show/component";
import { TripStatus } from "../../../models/trip/types";
import { updateTripStatus } from "../../../services/trip";
import { ReasonReject } from "../reasonReject/component";
import Loading from "../../loading/component";

export const Reject: FC<ModalProps> = ({ isOpen, setOpen }) => {
  const { t } = useTranslation(["modals"]);
  const { trip } = useContext(TripContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isReasonRejectOpen, setIsReasonRejectOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const rejectRequestTrip = async () => {
    if (!trip?.id) return;
    setIsDisabled(true);
    queryClient.cancelQueries({
      queryKey: ["trip", "tripDriver"],
      exact: true,
    });

    const response = await updateTripStatus(trip.id, {
      status: TripStatus.REFUSE_REQUEST_ANFITRION,
    });

    if (response?.status == 204) {
      setIsReasonRejectOpen(true);
    }
    setIsDisabled(false);
  };

  return (
    <>
      <Modal show={isOpen} className="reject-modal">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={setOpen}
          >
            <img src={navbarImages.cross} alt="" />
          </button>
          <h2>{t("reject.title")}</h2>
        </div>
        <div className="modal-body">
          <h3>{t("reject.subtitle")}</h3>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-red"
            onClick={() => !isDisabled && rejectRequestTrip()}
          >
            {isDisabled ? (
              <Loading height="1vh" color="#fff" />
            ) : (
              <p>{t("reject.yes")}</p>
            )}
          </button>
          <button type="button" className="btn btn-orange" onClick={setOpen}>
            <p>{t("reject.no")}</p>
          </button>
        </div>
      </Modal>
      <ReasonReject
        isOpen={isReasonRejectOpen}
        setOpen={() => setIsReasonRejectOpen(false)}
      />
    </>
  );
};
