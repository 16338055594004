import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { useQuery } from "react-query";
import { getDestinations } from "../../../../services/car";
import { useWindowSize } from "../../../../hooks/windowSize";
import {
  Destination,
  DestinationStatus,
} from "../../../../models/destination/types";
import { useTranslation } from "react-i18next";
import "./destinationCarousel.scss";
import { Link } from "react-router-dom";

export const DestinationCarousel = () => {
  const { data: destinations } = useQuery(["destinations"], getDestinations);
  const windowSize = useWindowSize();
  const { t } = useTranslation(["home"]);

  return (
    <>
      {destinations && (
        <div className="carousel-destinations-container">
          <h3>{t("destination.title")}</h3>
          <p>
            {t("destination.subtitle")}
            <span className="pink">{t("destination.pink")}</span>
            {t("destination.subtitle_extra")}
          </p>
          <Swiper
            slidesPerView={
              windowSize < 900
                ? 1
                : (destinations.length || 1) < 3
                ? destinations.length
                : 3
            }
            pagination={{
              clickable: true,
            }}
            slidesPerGroup={
              windowSize < 900
                ? 1
                : (destinations.length || 1) < 3
                ? destinations.length
                : 3
            }
            spaceBetween={20}
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation, Pagination]}
            className="swiper-suggestions"
          >
            {destinations?.map((destination: Destination, index: number) => (
              <SwiperSlide key={destination.id + index}>
                <Link
                  className="destination-card"
                  to={
                    destination.status !== DestinationStatus.COMING_SOON
                      ? "/" +
                        t("results", { ns: "routes" }) +
                        `?destinationId=${destination.id}&state=&latitud=20.6595382&longitud=-103.3494376&dateInitial=09%2F08%2F2023&hourInitial=12%3A00&dateEnd=12%2F08%2F2023&hourEnd=12%3A00&experiences=000000000&type=012345&transmission=01`
                      : ""
                  }
                >
                  <img
                    src={`https://upcdn.io/12a1xrm/thumbnail-reservation-index/test.balety.com/assets/${destination.name}.png`}
                    alt="destination"
                    className="destination-image"
                  />
                  <h4>{destination.name}</h4>
                  {destination.status === DestinationStatus.ACTIVE ? (
                    <span className="btn-status-destination">Activa</span>
                  ) : (
                    <span className="text-status-destination">
                      Próximamente
                    </span>
                  )}
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};
