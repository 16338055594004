import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { getBrand, getType, getVersion, getYear } from "../../services/car";
import { useEffect, useState } from "react";
import { CreateCarDTO } from "../../models/car/dtos";
import { createCar } from "../../services/car";
import { getUser } from "../../services/user";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { carSpecifications } from "../../models/car/dtos";
import { updateUserType } from "../../services/user";
import { UserType } from "../../models/user/types";
import { getToken, updateUserTypeLocal } from "../../services/localstorage";

export const useHostHome = () => {
  const { t } = useTranslation(["hostHome", "routes"]);
  const queryClient = useQueryClient();
  const [values, setValues] = useState({
    carBrand: "",
    carYear: "",
    carVersion: "",
    carType: "",
  });
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: user } = useQuery(["user"], getUser, {
    enabled: !!getToken(),
  });
  const navigate = useNavigate();

  const { data: brands, isSuccess: brandsSuccess } = useQuery(
    ["brands"],
    getBrand
  );

  const { data: types, isSuccess: typesSuccess } = useQuery(
    ["types", values.carBrand],
    () => getType(values),
    {
      enabled: !!values.carBrand,
    }
  );

  const { data: years, isSuccess: yearsSuccess } = useQuery(
    ["years", values.carType],
    () => getYear(values),
    {
      enabled: !!values.carType,
    }
  );

  const { data: versions, isSuccess: versionsSuccess } = useQuery(
    ["versions", values.carYear],
    () => getVersion(values),
    {
      enabled: !!values.carYear,
    }
  );

  const createNewCar = async (values: CreateCarDTO) => {
    setLoading(true);

    // Recolect car data
    const data = {
      brand: values.brand,
      model: values.model,
      year: values.year,
      version: values.version,
      userId: values.userId,
      step: 1,
    };

    try {
      // Update user type
      await updateUserType(UserType.DRIVER_HOST).then(() => {
        updateUserTypeLocal(UserType.DRIVER_HOST);
      });

      // Create new car
      const response = await createCar(data);
      const { message } = response?.data;

      // When create redirect to addcar flow
      const id = message.split(" ")[message.split(" ").length - 1];
      if (message) {
        navigate(`/${t("upload_car", { ns: "routes" })}`, {
          state: {
            step: 1,
            carId: id,
          },
        });
      }
    } catch (error) {
      // Check for any request error
      if (error instanceof AxiosError && error.response?.data.message) {
        setErrorMsg(error.response?.data.message);
        return null;
      }
      setErrorMsg(t("find_car.error_create_car"));
    } finally {
      // Reset any request from profile
      setLoading(false);
      queryClient.invalidateQueries(["profile"]);
    }
  };

  // Validate all fields are fill
  const formValidation = (values: carSpecifications): boolean => {
    if (
      !values.carBrand ||
      !values.carType ||
      !values.carVersion ||
      !values.carYear
    ) {
      setErrorMsg(t("find_car.validation_error"));
      return false;
    }
    return true;
  };

  const handleAddCar = () => {
    // If isn't login redirect to signup
    if (!user) {
      navigate(`/${t("signup", { ns: "routes" })}`);
      return;
    }

    // Form validation needs to pass to create new car
    if (!formValidation(values)) return;

    createNewCar({
      brand: values.carBrand,
      model: values.carType,
      version: values.carVersion,
      year: values.carYear,
      userId: user.id,
    });
  };

  // When enter to view scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    t,
    errorMsg,
    openAlert,
    setOpenAlert,
    values,
    setValues,
    brandsSuccess,
    brands,
    years,
    yearsSuccess,
    types,
    typesSuccess,
    versions,
    versionsSuccess,
    handleAddCar,
    loading,
  };
};
