import dayjs from "dayjs";
import { FC } from "react";
import { useQuery } from "react-query";
import authenticationImages from "../../../../../assets/images/authentication";
import i18n from "../../../../../i18n";
import {
  getHostByUserId,
  getHostExistByUserId,
} from "../../../../../services/host";
import { getUserByID } from "../../../../../services/user";
import "dayjs/locale/es";
import { capitalize } from "../../../../helpers/textHandler";
import { Skeleton, Tooltip } from "@mui/material";
import reservationImages from "../../../../../assets/images/reservations";
import navbarImages from "../../../../../assets/images/navbar";
import { useTranslation } from "react-i18next";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../../../services/fileUploader";
import { Trip } from "../../../../../models/trip/types";
import "./HostCard.scss";

interface HostCardProps {
  className?: string;
  userId: number;
  trip?: Trip;
}

export const HostCard: FC<HostCardProps> = ({ className, userId, trip }) => {
  const { t } = useTranslation(["car"]);

  const { data: userData } = useQuery(
    ["user", userId],
    () => getUserByID(userId),
    {
      enabled: !!userId,
    }
  );

  const { data: hostImage } = useQuery(
    ["hostImage", userId],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${userId}/foto_perfil`,
        `/${uploadioEnv}/user-${userId}`
      ),
    {
      enabled: !!userId,
    }
  );

  const { data: isHostCommercial } = useQuery(
    ["isHostCommercial", userId],
    () => getHostExistByUserId(userId.toString() || ""),
    {
      enabled: !!userId,
    }
  );

  const { data: hostC } = useQuery(
    ["hostC", userId],
    () => getHostByUserId(userId.toString() || ""),
    {
      enabled: isHostCommercial,
    }
  );

  if (isHostCommercial && hostC) {
    return (
      <div className={`user-card ${className}`}>
        <div className="user-info-image-container">
          <img
            src={
              hostImage
                ? hostImage.fileUrl.replace("raw", "profile-picture")
                : authenticationImages.profileDefault
            }
            className="user-img"
            alt=""
          />
          <div className="user-info">
            <h3 className="name" id="nombre" style={{ margin: "0" }}>
              {capitalize(hostC.comercialName)}
            </h3>
            <span className="hostC-indicator">
              {t("show.car_form.host_commercial")}
            </span>
            <p className="more">
              {t("show.car_form.host_card.create_date")}{" "}
              {dayjs(userData?.createDate, "DD/MM/YYYY")
                .locale(i18n.language)
                .format("MMMM YYYY")}
            </p>
          </div>
        </div>

        {trip && (
          <div className="btn-contact-container">
            <a
              id="contact"
              data-testid="contact-button"
              target="_blank"
              href={`https://wa.me/523318866005?text=Quiero%20hablar%20con%20mi%20anfitri%C3%B3n%20-%20${userData?.name}%20-%20sobre%20mi%20reservaci%C3%B3n%20${trip.tripCode}`}
              className="btn btn-contact"
            >
              <p>{t("show.contact_bar.share")}</p>
              <img src={navbarImages.messages} alt="" />
            </a>
          </div>
        )}
      </div>
    );
  }

  if (isHostCommercial === false) {
    return (
      <div className={`user-card ${className}`}>
        <div className="user-info-image-container">
          <img
            src={
              hostImage
                ? hostImage.fileUrl.replace("raw", "profile-picture")
                : authenticationImages.profileDefault
            }
            className="user-img"
            alt=""
          />
          <div className="user-info">
            <h3 className="name" id="nombre">
              {capitalize(userData?.name || "")}
            </h3>
            <div className="host-indicator-container">
              <Tooltip
                title={t("show.car_form.user_tooltip")}
                arrow
                className="user-tooltip"
              >
                <span className="hostC-indicator" data-testid="host-verified">
                  {t("show.car_form.verified_host")}{" "}
                </span>
              </Tooltip>
              <img
                src={reservationImages.checkVerified}
                className="check-host"
                alt=""
              />
            </div>
            <p className="more">
              {t("show.car_form.host_card.create_date")}{" "}
              {dayjs(userData?.createDate, "DD/MM/YYYY")
                .locale(i18n.language)
                .format("MMMM YYYY")}
            </p>
          </div>
        </div>

        {trip && (
          <div className="btn-contact-container">
            <a
              id="contact"
              data-testid="contact-button"
              target="_blank"
              href={`https://wa.me/523318866005?text=Quiero%20hablar%20con%20mi%20anfitri%C3%B3n%20-%20${userData?.name}%20-%20sobre%20mi%20reservaci%C3%B3n%20${trip.tripCode}`}
              className="btn btn-contact"
            >
              <p>{t("show.contact_bar.share")}</p>
              <img src={navbarImages.messages} alt="" />
            </a>
          </div>
        )}
      </div>
    );
  }

  return (
    <Skeleton
      variant="rounded"
      className={`user-card ${className}`}
      height={70}
    />
  );
};
