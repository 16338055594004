import { FC, useContext, useEffect, useRef, useState } from "react";
import { UserType } from "../../../../models/user/types";
import { LoginCollapse } from "../loginCollapse/component";
import navbarImages from "../../../../assets/images/navbar/index";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AuthCollapseProps,
  AuthContext,
  NavbarCollapseProps,
} from "../../../../components/navbar/types";
import { handleClickOutside } from "../../../helpers/dropdownBoxActions";
import {
  CardBecomeHost,
  ProfileLinker,
  UserTypeToggle,
} from "../profileAction/ProfileAction";

export const NavbarCollapse: FC<NavbarCollapseProps> = ({
  logged,
  user,
  burgerMenuShow,
  routeType,
  actionClick,
}) => {
  const navCollapse = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (navCollapse.current) {
      document.addEventListener(
        "click",
        (event) => {
          if (
            handleClickOutside(navCollapse, event) &&
            navCollapse.current?.style.left === "0px"
          )
            actionClick();
        },
        true
      );
    }
  }, [navCollapse.current?.style.left]);

  useEffect(() => {
    const appearNavbar = () => {
      if (navCollapse.current) {
        navCollapse.current.style.left = `${(burgerMenuShow ? 0 : -1) * 375}px`;
        burgerMenuShow
          ? (document.body.style.overflow = "hidden")
          : (document.body.style.overflow = "visible");
      }
    };
    appearNavbar();
  }, [burgerMenuShow]);

  return (
    <>
      <div
        ref={navCollapse}
        className="nav-collapse"
        id="navbarSupportedContent2"
      >
        <div className="list-unstyled mr-auto">
          {logged ? (
            <AuthNavbarCollapse user={user} routeType={routeType} />
          ) : (
            <UnAuthNavbarCollapse />
          )}
        </div>
      </div>
      {burgerMenuShow ? <div className="modal-backdrop back"></div> : null}
    </>
  );
};

const AuthNavbarCollapse: FC<AuthCollapseProps> = ({ user, routeType }) => {
  const { t } = useTranslation(["navbar", "routes"]);
  const auth = useContext(AuthContext);

  return (
    <>
      <div className="d-flex justify-content-between">
        {user && <ProfileLinker user={user} />}
      </div>

      {user?.type === UserType.DRIVER ? <CardBecomeHost /> : <UserTypeToggle />}
      <hr />
      <Link
        to={
          routeType === "driver"
            ? t("root", { ns: "routes" })
            : t("my_cars", { ns: "routes" })
        }
        data-testid="rent-menu-collapse"
        className="nav-item dropdown"
      >
        {routeType === "driver" ? (
          <>
            <img
              src={navbarImages.key}
              alt="car key"
              className="collapse-icons"
            />
            {t("auth_navbar_collapse.driver.rent")}
          </>
        ) : (
          <>
            <img
              src={navbarImages.myCars}
              alt="a little car"
              className="collapse-icons"
            />
            {t("auth_navbar_collapse.host.my_cars")}
          </>
        )}
      </Link>
      <hr />
      <Link
        className="nav-item dropdown"
        to={
          routeType === "driver"
            ? t("drivers", { ns: "routes" }) +
              "/" +
              t("trips", { ns: "routes" })
            : t("hosts", { ns: "routes" }) + "/" + t("trips", { ns: "routes" })
        }
      >
        <img
          src={navbarImages.calendar}
          alt="calendar for reservations"
          className="collapse-icons"
        />
        {t("auth_navbar_collapse.reservations")}
      </Link>
      <hr />
      <Link
        to={
          routeType === "driver"
            ? `/${t("drivers", { ns: "routes" })}/${t("favorites", {
                ns: "routes",
              })}`
            : `/${t("hosts", { ns: "routes" })}/${t("progress", {
                ns: "routes",
              })}`
        }
        className="nav-item dropdown"
      >
        {routeType === "driver" ? (
          <>
            <img
              src={navbarImages.heart}
              alt="your favorites"
              className="collapse-icons"
            />
            {t("auth_navbar_collapse.driver.favorites")}
          </>
        ) : (
          <>
            <img
              src={navbarImages.progress}
              alt="progress in rentals"
              className="collapse-icons"
            />
            {t("auth_navbar_collapse.host.progress")}
          </>
        )}
      </Link>
      <hr />
      <a
        rel="noreferrer"
        href="https://ayuda.balety.com"
        target="_blank"
        className="nav-item dropdown"
      >
        <img
          src={navbarImages.help}
          alt="need help"
          className="collapse-icons"
        />
        {t("auth_navbar_collapse.help")}
      </a>
      <hr />
      <Link
        to={
          routeType === "driver"
            ? t("drivers", { ns: "routes" }) + "/" + t("chat", { ns: "routes" })
            : t("hosts", { ns: "routes" }) + "/" + t("chat", { ns: "routes" })
        }
        className="nav-item dropdown"
      >
        <img
          src={navbarImages.comment}
          alt="chat icon"
          className="collapse-icons"
        />
        {t("auth_navbar_collapse.chat")}
      </Link>
      <hr />
      <a className="item logout" onClick={auth.logout}>
        <img
          src={navbarImages.logout}
          alt="logout session"
          className="collapse-icons"
        />
        {t("auth_navbar_collapse.logout")}
      </a>
      <hr />
    </>
  );
};

const UnAuthNavbarCollapse = () => {
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showEarnMoney, setShowEarnMoney] = useState<boolean>(false);
  const { t } = useTranslation(["navbar", "routes"]);

  return (
    <>
      <Link
        to={t("root", { ns: "routes" })}
        data-testid="rent-menu-collapse"
        className="nav-item dropdown"
      >
        <img src={navbarImages.key} className="collapse-icons" alt="rent" />
        {t("no_auth_navbar_collapse.rent")}
      </Link>
      <hr />
      <div className="d-flex justify-content-between">
        <div className="w-100">
          <Link
            to={t("host", { ns: "routes" })}
            data-testid="earn-money-menu-collapse"
            className="nav-item dropdown"
          >
            <img
              src={navbarImages.money}
              alt="earn money rentals"
              className="collapse-icons"
            />
            {t("no_auth_navbar_collapse.earn_money_with_my_car")}
          </Link>
        </div>
        <div
          className="w-25 text-right"
          style={{ margin: "auto 0" }}
          onClick={() => setShowEarnMoney(!showEarnMoney)}
        >
          <img id="drop_arrow" src={navbarImages.downArrow} alt="" />
        </div>
      </div>
      {showEarnMoney ? (
        <ul style={{ paddingLeft: "2rem" }} id="profile_content1">
          <Link className="item" to={t("host", { ns: "routes" })}>
            {t("no_auth_navbar_collapse.upload_my_car")}
          </Link>
          <div className="dropdown-divider"></div>
          <Link className="item" to={t("commercial_host", { ns: "routes" })}>
            {t("no_auth_navbar_collapse.own_fleets")}
          </Link>
        </ul>
      ) : null}
      <hr />
      <a
        className="nav-item dropdown"
        data-testid="login-menu-collapse"
        onClick={() => setShowLogin(!showLogin)}
      >
        <img
          src={navbarImages.loginUser}
          className="collapse-icons"
          alt="login user"
        />
        {t("no_auth_navbar_collapse.login")}
      </a>
      <hr />
      <Link
        data-testid="create-account-menu-collapse"
        to={t("signup", { ns: "routes" })}
        className="nav-item dropdown "
      >
        <img
          src={navbarImages.signup}
          alt="signup new user"
          className="collapse-icons"
        />
        {t("no_auth_navbar_collapse.create_an_account")}
      </Link>
      <LoginCollapse
        showLogin={() => setShowLogin(!showLogin)}
        show={showLogin}
      />
    </>
  );
};
