import {
  Button,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getMoffin } from "../../services/documents";
import { useQuery, useQueryClient } from "react-query";
import { useEffect, useRef, useState } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import navbarImages from "../../assets/images/navbar";
import UploadDropZone from "../uploadDropZone/component";
import { getFileDetailsFromPath } from "../../services/fileUploader";
import { DocStatus, User } from "../../models/user/types";
import { updateUserCompleted } from "../../services/user";
import { KycStatus } from "../../views/profile/types";

const DocumentIconStatus = (props: StepIconProps) => {
  const { sx, icon } = props;

  return (
    <>
      {icon === "NOT_UPLOADED" ? (
        <WarningAmberIcon sx={sx} />
      ) : icon === "FAIL" ? (
        <HighlightOffIcon sx={sx} />
      ) : icon === "PENDING" ? (
        <HistoryToggleOffIcon sx={sx} />
      ) : (
        <CheckCircleOutlineIcon sx={sx} />
      )}
    </>
  );
};

const ProfileDocumentation = ({ user }: { user: User }) => {
  const queryClient = useQueryClient();
  const ref = useRef(null);
  const { t } = useTranslation(["profile"]);
  const [proofOfAddress, setProofOfAddress] = useState<
    { url: string; mime: string } | undefined
  >();
  const [bankStatement, setBankStatement] = useState<
    { url: string; mime: string } | undefined
  >();
  const [isClick, setIsClick] = useState(false);
  const [url, setUrl] = useState("");
  const { data: moffinUrl } = useQuery(["moffin"], getMoffin, {
    enabled:
      isClick &&
      user.docOfficialDocuments === KycStatus.NOT_STARTED &&
      url === "",
    refetchOnMount: false,
  });

  const getFileDetails = async (id: number) => {
    await getFileDetailsFromPath(
      `/${process.env.REACT_APP_UPLOAD_IO_ENV}/user-${id}/comprobante_domicilio`,
      setProofOfAddress
    );
    await getFileDetailsFromPath(
      `/${process.env.REACT_APP_UPLOAD_IO_ENV}/user-${id}/estado_cuenta`,
      setBankStatement
    );
  };

  useEffect(() => {
    if (user) {
      getFileDetails(user.id);
    }
  }, [user]);

  useEffect(() => {
    if (moffinUrl) {
      updateUserCompleted({
        moffinReportId: moffinUrl.id,
      });
    }

    if (moffinUrl && !url) {
      setUrl(moffinUrl.response.formURL);
    }
  }, [moffinUrl]);

  useEffect(() => {
    if (url !== "" && isClick) {
      window.open(url, "_blank");
    }
  }, [url, isClick]);

  const updateUserDocumentation = async (
    document: "docProofOfAddress" | "docBankStatement",
    status: DocStatus
  ) => {
    await updateUserCompleted({
      [document]: status,
    });
    queryClient.invalidateQueries(["profile"]);
  };

  useEffect(() => {
    window.addEventListener(
      "focus",
      () => {
        if (url !== "") {
          queryClient.invalidateQueries(["profile"]);
        }
      },
      true
    );
  }, [ref.current]);

  useEffect(() => {
    if (!isClick) {
      queryClient.invalidateQueries(["profile"]);
    }
  }, [isClick]);

  return (
    <article className="profile-documents-container" ref={ref}>
      <h2>{t("profile_documents.title")}</h2>
      <div className="divider"></div>
      <p>{t("profile_documents.subtitle")}</p>

      <Stepper orientation="vertical" sx={{ width: "100%" }}>
        <Step active>
          <StepLabel
            StepIconComponent={DocumentIconStatus}
            icon={
              user.docOfficialDocuments === KycStatus.NOT_STARTED
                ? "NOT_UPLOADED"
                : user.docOfficialDocuments === KycStatus.PENDING
                ? "PENDING"
                : user.docOfficialDocuments === KycStatus.SUCCESS
                ? "SUCCESS"
                : "FAIL"
            }
            StepIconProps={{
              sx: {
                color:
                  user.docOfficialDocuments === KycStatus.NOT_STARTED
                    ? "#3cb2b9"
                    : user.docOfficialDocuments === KycStatus.PENDING
                    ? "#ffab03"
                    : user.docOfficialDocuments === KycStatus.SUCCESS
                    ? "#98d075"
                    : "#eb5757",
              },
            }}
          >
            <h3>{t("profile_documents.id")}</h3>
          </StepLabel>
          <StepContent sx={{ paddingLeft: "32px" }}>
            <p>{t("profile_documents.needed_documents.title")}</p>
            <ul>
              <li>{t("profile_documents.needed_documents.first")}</li>
              <li>{t("profile_documents.needed_documents.second")}</li>
            </ul>
            {user.docOfficialDocuments === KycStatus.NOT_STARTED && (
              <div className="document-verification">
                <img
                  src={navbarImages.minLogoOrange}
                  style={{ width: "50px" }}
                  alt=""
                />
                <Button
                  className="verification-button"
                  onClick={() => {
                    setIsClick((prev) => !prev);
                  }}
                >
                  {t("profile_documents.moffin")}
                </Button>
              </div>
            )}
          </StepContent>
        </Step>
        <Step active>
          <StepLabel
            StepIconComponent={DocumentIconStatus}
            icon={
              user.docBankStatement === DocStatus.NOT_UPLOADED
                ? "NOT_UPLOADED"
                : user.docBankStatement === DocStatus.PENDING
                ? "PENDING"
                : user.docBankStatement === DocStatus.VALIDATED
                ? "SUCCESS"
                : "FAIL"
            }
            StepIconProps={{
              sx: {
                color:
                  user.docBankStatement === DocStatus.NOT_UPLOADED
                    ? "#3cb2b9"
                    : user.docBankStatement === DocStatus.PENDING
                    ? "#ffab03"
                    : user.docBankStatement === DocStatus.VALIDATED
                    ? "#98d075"
                    : "#eb5757",
              },
            }}
          >
            <h3>{t("profile_documents.driving_license.title")}</h3>
          </StepLabel>
          <StepContent sx={{ paddingLeft: "32px" }}>
            <p>{t("profile_documents.driving_license.warning")}</p>
            <UploadDropZone
              allow="file,image"
              fileDetails={{
                url: bankStatement?.url || "",
                type: bankStatement?.mime || "",
              }}
              height="120px"
              path={{
                fileName: "estado_cuenta",
              }}
              showRemove={user.docBankStatement !== DocStatus.VALIDATED}
              onUpload={(url, mime) => {
                updateUserDocumentation("docBankStatement", DocStatus.PENDING);
                setBankStatement({ url, mime });
              }}
              onDelete={() => {
                updateUserDocumentation(
                  "docBankStatement",
                  DocStatus.NOT_UPLOADED
                );
                setBankStatement(undefined);
              }}
            />
          </StepContent>
        </Step>
        <Step active>
          <StepLabel
            StepIconComponent={DocumentIconStatus}
            icon={
              user.docProofOfAddress === DocStatus.NOT_UPLOADED
                ? "NOT_UPLOADED"
                : user.docProofOfAddress === DocStatus.PENDING
                ? "PENDING"
                : user.docProofOfAddress === DocStatus.VALIDATED
                ? "SUCCESS"
                : "FAIL"
            }
            StepIconProps={{
              sx: {
                color:
                  user.docProofOfAddress === DocStatus.NOT_UPLOADED
                    ? "#3cb2b9"
                    : user.docProofOfAddress === DocStatus.PENDING
                    ? "#ffab03"
                    : user.docProofOfAddress === DocStatus.VALIDATED
                    ? "#98d075"
                    : "#eb5757",
              },
            }}
          >
            <h3>{t("profile_documents.residence_proof_title")}</h3>
          </StepLabel>
          <StepContent sx={{ paddingLeft: "32px" }}>
            <p>{t("profile_documents.residence_proof_warning")}</p>
            <UploadDropZone
              allow="file,image"
              fileDetails={{
                url: proofOfAddress?.url || "",
                type: proofOfAddress?.mime || "",
              }}
              height="120px"
              path={{
                fileName: "comprobante_domicilio",
              }}
              showRemove={user.docProofOfAddress !== DocStatus.VALIDATED}
              onUpload={(url, mime) => {
                setProofOfAddress({ url, mime });
                updateUserDocumentation("docProofOfAddress", DocStatus.PENDING);
              }}
              onDelete={() => {
                updateUserDocumentation(
                  "docProofOfAddress",
                  DocStatus.NOT_UPLOADED
                );
                setProofOfAddress(undefined);
              }}
            />
          </StepContent>
        </Step>
      </Stepper>
    </article>
  );
};

export default ProfileDocumentation;
