import { useState, useEffect } from "react";
import {
  fileSrc,
  getFileDetailsFromPath,
  listFiles,
  uploadioEnv,
} from "../../services/fileUploader";
import { getUserId } from "../../services/localstorage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  DocPolicyStatus,
  HostForm,
  TypeInsurance,
} from "../../models/host/types";
import { createHost, getHost } from "../../services/host";
import { useFormik } from "formik";
import { hostCommercialSchema } from "../../views/commercialSignup/form";
import { SelectChangeEvent } from "@mui/material";

export default function useHostCommercialSignUp() {
  const userId = getUserId();
  const [rentPoliciesFile, setRentPoliciesFile] = useState<
    fileSrc | undefined
  >();
  const { t } = useTranslation(["commercialSignup", "profile"]);
  const [insurance, setInsurance] = useState<number>(0);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const { data: host } = useQuery(["host"], getHost, {
    enabled: !!getUserId(),
    retry: false,
  });

  useEffect(() => {
    if (host) {
      navigate("/" + t("profile", { ns: "routes" }));
    }
  }, []);

  const fetchHostCommercial = async (values: HostForm) => {
    // Get user id
    const id = getUserId();

    if (!id) return;
    try {
      // Create new host
      await createHost({
        userId: parseInt(id),
        comercialName: values.comercialName,
        businessName: values.businessName,
        rfc: values.rfc,
        legalAddress: values.legalAddress,
        insurance: values.insurance,
        rentPoliciesStatus: values.rentPoliciesStatus,
      });

      // Navigate to profile
      navigate("/" + t("profile", { ns: "routes" }));
    } catch (error) {
      setErrorMsg(t("signup_error"));
      setOpenAlert(true);
    }
  };

  // Host commercial form
  const formikHostCommercial = useFormik<HostForm>({
    initialValues: {
      comercialName: "",
      businessName: "",
      rfc: "",
      legalAddress: "",
      insurance: TypeInsurance.OUR_INSURANCE,
      rentPoliciesStatus: DocPolicyStatus.NOT_UPLOADED,
    },
    validationSchema: hostCommercialSchema,
    onSubmit: fetchHostCommercial,
  });

  // Handle insurance value
  const handleInsurance = (event: SelectChangeEvent<number>) => {
    formikHostCommercial.setFieldValue("insurance", event.target.value);
    setInsurance(event.target.value as number);
  };

  // Get dinamically the image source
  useEffect(() => {
    (async () => {
      try {
        const result = await listFiles(`/${uploadioEnv}/user-${userId}`);
        if (!result || !result.items || result.items.length == 0) return;
        // search the rent policies file
        const index = result.items.findIndex((f: { filePath?: string }) => {
          if (!f.filePath) return false;
          return f.filePath.includes("politicas_de_renta");
        });

        if (index !== -1) {
          await getFileDetailsFromPath(
            result.items[index].filePath,
            setRentPoliciesFile
          );
        }
      } catch (_) {}
    })();
  }, []);

  return {
    t,
    rentPoliciesFile,
    setRentPoliciesFile,
    formikHostCommercial,
    errorMsg,
    openAlert,
    setOpenAlert,
    insurance,
    handleInsurance,
  };
}
