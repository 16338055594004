import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { useWindowSize } from "../../../../hooks/windowSize";
import "./benefitsCarousel.scss";
import a_homepageImages from "../../../../assets/images/home/a_homepage";
import { Benefit } from "../../../../models/benefits/types";
import { useHostHome } from "../../../../hooks/hostHome/useHostHome";

export const BenefitsCarousel = () => {
  const benefits = [
    {
      id: 0,
      img: a_homepageImages.moneyPerk,
      title: "benefits.earn_more",
      subtitle: "benefits.increase_earnings",
    },
    {
      id: 1,
      img: a_homepageImages.listPerk,
      title: "benefits.free_listing",
      subtitle: "benefits.upload_your_car",
    },
    {
      id: 2,
      img: a_homepageImages.pricePerk,
      title: "benefits.you_put_price",
      subtitle: "benefits.determine_your_car_price",
    },
    {
      id: 3,
      img: a_homepageImages.datesPerk,
      title: "benefits.control_dates",
      subtitle: "benefits.manage_disponibility",
    },
    {
      id: 4,
      img: a_homepageImages.wayPerk,
      title: "benefits.delivery_points",
      subtitle: "benefits.select_delibery_devolution",
    },
    {
      id: 5,
      img: a_homepageImages.carRulesPerk,
      title: "benefits.your_rules",
      subtitle: "benefits.feel_calm",
    },
    {
      id: 6,
      img: a_homepageImages.verifiedPerk,
      title: "benefits.verified_users",
      subtitle: "benefits.inspection_process",
    },
    {
      id: 7,
      img: a_homepageImages.controlPerk,
      title: "benefits.you_have_control",
      subtitle: "benefits.each_trip",
    },
    {
      id: 8,
      img: a_homepageImages.insurancePerk,
      title: "benefits.insured_trips",
      subtitle: "benefits.qualitas_insurance",
    },
    {
      id: 9,
      img: a_homepageImages.discountPerk,
      title: "benefits.exclusive_discounts",
      subtitle: "benefits.enjoy_benefits",
    },
  ];
  const windowSize = useWindowSize();
  const { t } = useHostHome();

  return (
    <>
      {benefits && (
        <div className="carousel-benefits-container">
          <h3>{t("benefits.why_host")}</h3>
          <p>{t("benefits.with_you")}</p>
          <Swiper
            slidesPerView={
              windowSize < 900
                ? 1
                : (benefits.length || 1) < 3
                ? benefits.length
                : 3
            }
            pagination={{
              clickable: true,
            }}
            slidesPerGroup={
              windowSize < 900
                ? 1
                : (benefits.length || 1) < 3
                ? benefits.length
                : 3
            }
            spaceBetween={20}
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation, Pagination]}
            className="swiper-suggestions"
          >
            {benefits.map((benefit: Benefit, index: number) => (
              <SwiperSlide key={benefit.id + index}>
                <div className="benefit-card">
                  <img
                    src={benefit.img}
                    alt="benefit"
                    className="benefit-image"
                  />
                  <h4>{t(benefit.title)}</h4>
                  <p className="subtitle">{t(benefit.subtitle)}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};
