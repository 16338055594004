import { FormControl, MenuItem, Select } from "@mui/material";
import authenticationImages from "../../assets/images/authentication";
import { DocPolicyStatus, TypeInsurance } from "../../models/host/types";
import "./CommercialSignup.scss";
import Autocomplete from "react-google-autocomplete";
import UploadDropZone from "../../components/uploadDropZone/component";
import useHostCommercialSignUp from "../../hooks/commercialSignup/useHostCommercialSignup";
import Toast from "../../components/toast/component";
import { Helmet } from "react-helmet";

export const CommercialSignup = () => {
  const {
    t,
    rentPoliciesFile,
    setRentPoliciesFile,
    formikHostCommercial,
    errorMsg,
    openAlert,
    setOpenAlert,
    insurance,
    handleInsurance,
  } = useHostCommercialSignUp();

  const styles = {
    signupButton: {
      backgroundColor:
        formikHostCommercial.isValid && formikHostCommercial.dirty
          ? "#f76f8e"
          : "#828282",
    },
  };

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/crear-cuenta-comercial"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/crear-cuenta-comercial"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/crear-cuenta-comercial"
        />
      </Helmet>
      <article className="comercial-signup-container">
        {errorMsg && (
          <Toast open={openAlert} setOpen={setOpenAlert} type="error">
            {errorMsg}
          </Toast>
        )}
        <div className="join-container">
          <div className="title-container">
            <h1 data-testid="title">{t("commercial_host")}</h1>
          </div>
          <img src={authenticationImages.comercialSignup} alt="" />
          <div className="info-container">
            <p data-testid="slogan">
              {t("complete_data")}
              <br />
              <a href="">{t("know_benefits")}</a>
            </p>
          </div>
        </div>
        <div className="comercial-signup-form">
          <form>
            <div className="input-form">
              <label data-testid="commecial-name-label">
                {t("commecial_name")}
              </label>
              <p>{t("enter_name")}</p>
              <input
                id="commercial_name_input"
                name="name"
                type="text"
                onChange={formikHostCommercial.handleChange("comercialName")}
                placeholder="Ingresa el nombre de tu empresa"
              />
            </div>
            <div className="input-form">
              <label data-testid="business-name-label">
                {t("business_name")}
              </label>
              <p>{t("official_name")}</p>
              <input
                id="business_name_input"
                name="name"
                onChange={formikHostCommercial.handleChange("businessName")}
                type="text"
                placeholder="Ingresa la razón social de tu empresa"
              />
            </div>
            <div className="input-form">
              <label data-testid="rfc-label">{t("RFC")}</label>
              <input
                id="rfc_input"
                name="name"
                onChange={formikHostCommercial.handleChange("rfc")}
                type="text"
                placeholder="Ingresa tu RFC"
              />
            </div>
            <div className="input-form">
              <label data-testid="tax-residence-label">
                {t("tax_residence")}
              </label>
              <Autocomplete
                id="txtUbication"
                className="w-100"
                apiKey={process.env.REACT_APP_GOOGLE_MAPS}
                options={{
                  componentRestrictions: { country: "mx" },
                  fields: [
                    "geometry",
                    "formatted_address",
                    "place_id",
                    "address_components",
                    "name",
                  ],
                  types: [],
                }}
                onPlaceSelected={(place) =>
                  formikHostCommercial.setFieldValue(
                    "legalAddress",
                    place.formatted_address
                  )
                }
                language="es-419"
              />
            </div>
            <div className="input-form">
              <label data-testid="own-insurance-label">
                {t("own_insurance")}
              </label>
              <p>
                {t("already_have_insurance")}
                <a href="https://ayuda.balety.com" target="_blank">
                  {t("learn_more")}
                </a>
                {t("about")}
              </p>
              <FormControl fullWidth>
                <Select
                  labelId="own_insurance_select"
                  id="own_insurance_select"
                  className="dropdown"
                  label=""
                  value={insurance}
                  onChange={(e) => handleInsurance(e)}
                >
                  <MenuItem value={0}>{t("no")}</MenuItem>
                  <MenuItem value={1}>{t("yes")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            {formikHostCommercial.values.insurance ==
            TypeInsurance.HOST_INSURANCE ? (
              <div id="rent_policies" className="input-form">
                <label>{t("upload_policies")}</label>
                <p>{t("upload_explanation")}</p>
                <UploadDropZone
                  allow="file,image"
                  fileDetails={{
                    url: rentPoliciesFile?.url || "",
                    type: rentPoliciesFile?.mime || "",
                  }}
                  path={{
                    fileName: "politicas_de_renta",
                  }}
                  onUpload={(url, mime) => {
                    setRentPoliciesFile({ url, mime });
                    formikHostCommercial.setFieldValue(
                      "rentPoliciesStatus",
                      DocPolicyStatus.PENDING
                    );
                  }}
                  onDelete={() => {
                    setRentPoliciesFile(undefined);
                    formikHostCommercial.setFieldValue(
                      "rentPoliciesStatus",
                      DocPolicyStatus.NOT_UPLOADED
                    );
                  }}
                  showRemove
                />
              </div>
            ) : null}
            <div className="button-form">
              <button
                id="comercial_signup"
                type="button"
                className="btn btn-orange"
                style={styles.signupButton}
                onClick={() => formikHostCommercial.handleSubmit()}
              >
                {t("ready")}
              </button>
            </div>
          </form>
        </div>
      </article>
    </>
  );
};
