import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import helpImages from "../../assets/images/help";
import ac_homepageImages from "../../assets/images/home/ac_homepage";
import h_homepageImages from "../../assets/images/home/h_homepage";
import { HostSplitInformation } from "../../lib/components/homeTools/splitInformation/component";
import { Steps } from "../../lib/components/homeTools/steps/steps";
import { useQuery } from "react-query";
import { getUser } from "../../services/user";
import "./CommercialHome.scss";
import { getToken } from "../../services/localstorage";
import { Helmet } from "react-helmet";
import { Perks } from "../../lib/components/homeTools/perks/perks";
import { useWindowSize } from "../../hooks/windowSize";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";

interface Qa {
  [key: string]: boolean;
}

export const CommercialHome = () => {
  const { t } = useTranslation(["commercialHome"]);
  const { data: user } = useQuery(["user"], getUser, { enabled: !!getToken() });
  const windowSize = useWindowSize();
  const [qa, setQa] = useState<Qa>({
    first: true,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  });

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta property="og:title" content={t("seo.og_title")} />
        <meta property="og:description" content={t("seo.og_description")} />
        <meta name="robots" content="index,follow" />

        <link rel="canonical" href="https://romio.mx/anfitrion-comercial" />
        <meta
          property="og:url"
          content="https://romio.mx/anfitrion-comercial"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={t("seo.og_locale")} />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/anfitrion-comercial"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/anfitrion-comercial"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/anfitrion-comercial"
        />
      </Helmet>
      <main className="main-ac-homepage">
        <div className="ac-find">
          <div className="ac-title">
            <h1 data-testid="main-title">{t("become_a_business_host")}</h1>
            <p>{t("attract_more_customers")}</p>
            <div className="btn-container">
              <Link
                to={
                  user
                    ? `/${t("commercial_signup", { ns: "routes" })}`
                    : `/${t("signup", { ns: "routes" })}`
                }
                className="btn btn-orange"
                data-testid="start-button"
              >
                {t("starts_now")}
              </Link>
            </div>
          </div>
          <img
            id="ac_background"
            src={ac_homepageImages.acBackground}
            className="w-100 ac-background"
            alt="a person looking a phone"
          />
        </div>
      </main>

      <Perks
        title={
          <div className="title-container">
            <h2 data-testid="benefits-title" className="title">
              {t("benefits.some_benefits")}
            </h2>
            <p data-testid="benefits-subtitle">{t("benefits.upload_cars")}</p>
          </div>
        }
        perkList={[
          <div className="benefit">
            <div className="benefit-information">
              <h3>{t("benefits.free_list")}</h3>
              <p>{t("benefits.free_upload")}</p>
            </div>
          </div>,
          <div className="benefit">
            <div className="benefit-information">
              <h3>{t("benefits.win_more")}</h3>
              <p>{t("benefits.earn_eighty_percent")}</p>
            </div>
          </div>,
          <div className="benefit">
            <div className="benefit-information">
              <h3>{t("benefits.your_politics")}</h3>
              <p>{t("benefits.enlist_your_terms")}</p>
            </div>
          </div>,
          <div className="benefit">
            <div className="benefit-information">
              <h3>{t("benefits.easily_manage")}</h3>
              <p>{t("benefits.create_tools")}</p>
            </div>
          </div>,
          <div className="benefit">
            <div className="benefit-information">
              <h3>{t("benefits.exclusive_campaigns")}</h3>
              <p>{t("benefits.participate_in_campaigns")}</p>
            </div>
          </div>,
          <div className="benefit">
            <div className="benefit-information">
              <h3>{t("benefits.increase_your_rate")}</h3>
              <p>{t("benefits.increase_car_rate")}</p>
            </div>
          </div>,
          <div className="benefit">
            <div className="benefit-information">
              <h3>{t("benefits.trademark")}</h3>
              <p>{t("benefits.recognize")}</p>
            </div>
          </div>,
          <div className="benefit">
            <div className="benefit-information">
              <h3>{t("benefits.safe_customers")}</h3>
              <p>{t("benefits.verified_users")}</p>
            </div>
          </div>,
          <div className="benefit">
            <div className="benefit-information">
              <h3>{t("benefits.more_exposure")}</h3>
              <p>{t("benefits.access_to_our_network")}</p>
            </div>
          </div>,
        ]}
      />

      <article className="ac-info">
        <div className="row">
          <div className="content mx-auto">
            <div className="col-12">
              <h3 className="signup">{t("benefits.verified_users")}</h3>
              <HashLink
                to={
                  user
                    ? `/${t("commercial_signup", {
                        ns: "routes",
                      })}#h_navbar_container`
                    : `/${t("signup", { ns: "routes" })}#h_navbar_container`
                }
                className="btn btn-orange"
              >
                {t("benefits.sign_up!")}
              </HashLink>
            </div>
          </div>
        </div>
      </article>

      <div className="steps-container">
        <Steps
          stepList={[
            <div className="step">
              <img src={h_homepageImages.unOne} alt="number one" />
              <div className="step-info">
                <h3 data-testid="step-one-title">
                  {t("steps.create_account")}
                </h3>
                <p>{t("steps.create_account_and_list")}</p>
              </div>
            </div>,
            <div className="step">
              <img src={h_homepageImages.unTwo} alt="number two" />
              <div className="step-info">
                <h3 data-testid="step-two-title">{t("steps.choose")}</h3>
                <p>{t("steps.in_your_profile")}</p>
              </div>
            </div>,
            <div className="step">
              <img src={h_homepageImages.unThree} alt="number three" />
              <div className="step-info">
                <h3 data-testid="step-three-title">
                  {t("steps.receive_customers")}
                </h3>
                <p>{t("steps.accept_clients")}</p>
              </div>
            </div>,
          ]}
          title={
            <div className="steps-title-container">
              <h3 data-testid="steps-title">
                {t("steps.how_to_be_commercial_host")}
              </h3>
              <p>{t("steps.just_few_steps")}</p>
            </div>
          }
          image={
            <img
              src={
                windowSize < 800
                  ? ac_homepageImages.stepsMobile
                  : ac_homepageImages.steps
              }
              alt="a person approaching a car"
            />
          }
        />
      </div>

      <article className="ac-info pt-0">
        <HostSplitInformation
          principalClass="ac-win"
          imageClass="col-md-5"
          title={
            <h3 data-testid="start-now-title">
              {t("split_information.operate_as_always")}
            </h3>
          }
          subtitle={
            <h4 data-testid="start-now-subtitle">
              {t("split_information.you_are_free_to_operate")}
            </h4>
          }
          content={
            <>
              <p>{t("split_information.business_hosts")}</p>
              <p>{t("split_information.business_hosts_extra")}</p>
              <HashLink
                to={
                  user
                    ? `/${t("commercial_signup", {
                        ns: "routes",
                      })}#h_navbar_container`
                    : `/${t("signup", { ns: "routes" })}#h_navbar_container`
                }
                className="btn btn-orange"
              >
                {t("split_information.starts_now")}
              </HashLink>
            </>
          }
          image={
            <img
              src={h_homepageImages.always}
              className="w-100"
              alt="a person writting "
            />
          }
          componentColor={""}
          componentOrder={"order-bottom-right col-md-6"}
          hasHeader={false}
        />
      </article>

      <article className="ac-faq">
        <h2 className="ac-faq-title" data-testid="faq-title">
          {t("faq.frequent_questions")}
        </h2>
        <div className="questions-container">
          <div
            className="faq"
            id="Q28"
            onClick={() =>
              setQa((prev) => ({ ...prev, first: !prev["first"] }))
            }
          >
            <p className="option-title" data-testid="faq-one">
              {t("faq.first")}
            </p>
            <img src={helpImages.plus} alt="plus" />
          </div>
          <div className="dropdown-divider"></div>
          {qa["first"] && (
            <div className="drop-options" id="FAQ28">
              <div className="center-container">
                <div className="options">
                  <p className="option">{t("faq.first_answer")}</p>
                </div>
              </div>
            </div>
          )}
          <div
            className="faq"
            id="Q29"
            onClick={() =>
              setQa((prev) => ({ ...prev, second: !prev["second"] }))
            }
          >
            <p className="option-title" data-testid="faq-two">
              {t("faq.second")}
            </p>
            <img src={helpImages.plus} alt="plus" />
          </div>
          <div className="dropdown-divider"></div>
          {qa["second"] && (
            <div className="drop-options" id="FAQ29">
              <div className="center-container">
                <div className="options">
                  <p className="option" data-testid="faq-two">
                    {t("faq.second_answer")}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div
            className="faq"
            id="Q30"
            onClick={() =>
              setQa((prev) => ({ ...prev, third: !prev["third"] }))
            }
          >
            <p className="option-title" data-testid="faq-three">
              {t("faq.third")}
            </p>
            <img className="min" src={helpImages.plus} alt="plus" />
          </div>
          <div className="dropdown-divider"></div>
          {qa["third"] && (
            <div className="drop-options" id="FAQ30">
              <div className="center-container">
                <div className="options">
                  <p className="option">{t("faq.third_answer")}</p>
                </div>
              </div>
            </div>
          )}
          <div
            className="faq"
            id="Q31"
            onClick={() =>
              setQa((prev) => ({ ...prev, fourth: !prev["fourth"] }))
            }
          >
            <p className="option-title" data-testid="faq-four">
              {t("faq.fourth")}
            </p>
            <img className="min" src={helpImages.plus} alt="min" />
          </div>
          <div className="dropdown-divider"></div>
          {qa["fourth"] && (
            <div className="drop-options" id="FAQ31">
              <div className="center-container">
                <div className="options">
                  <p className="option">{t("faq.fourth_answer")}</p>
                </div>
              </div>
            </div>
          )}
          <div
            className="faq"
            id="Q32"
            onClick={() =>
              setQa((prev) => ({ ...prev, fifth: !prev["fifth"] }))
            }
          >
            <p className="option-title" data-testid="faq-five">
              {t("faq.fifth")}
            </p>
            <img className="min" src={helpImages.plus} alt="min" />
          </div>
          <div className="dropdown-divider"></div>
          {qa["fifth"] && (
            <div className="drop-options" id="FAQ32">
              <div className="center-container">
                <div className="options">
                  <p className="option">{t("faq.fifth_answer")}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </article>
    </>
  );
};
