import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ImgHTMLAttributes,
} from "react";
import "./SplitInformation.scss";

interface SplitInformationProps {
  title: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >;
  subtitle: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >;
  content: DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >;
  image: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  componentColor: string;
  componentOrder: string;
}

export const SplitInformation: FC<SplitInformationProps> = ({
  title,
  subtitle,
  content,
  image,
  componentColor,
  componentOrder,
}) => {
  return (
    <div className="row un-win" style={{ backgroundColor: componentColor }}>
      <div className="content">
        <div
          className={
            "col-12 col-sm-12 col-md-6 col-xl-6 container-text " +
            componentOrder
          }
        >
          <>
            {title}
            {subtitle}
            {content}
          </>
        </div>
        <div className={"image-container col-12 col-sm-12 col-md-6"}>
          <>{image}</>
        </div>
      </div>
    </div>
  );
};

interface HostSplitInformationProps {
  principalClass: string;
  imageClass: string;
  title: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >;
  subtitle: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >;
  content: DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >;
  image?: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  componentColor: string;
  componentOrder: string;
  hasHeader: boolean;
}

export const HostSplitInformation: FC<HostSplitInformationProps> = ({
  principalClass,
  imageClass,
  title,
  subtitle,
  content,
  image,
  componentColor,
  componentOrder,
  hasHeader,
}) => {
  return (
    <div
      className={"row " + principalClass}
      style={{ backgroundColor: componentColor }}
    >
      {hasHeader && (
        <div className="content">
          <div className={"col-12 text-title "}>
            <>{title}</>
          </div>
        </div>
      )}
      {hasHeader && (
        <div className="content">
          <div className={"col-12 col-sm-12 container-text " + componentOrder}>
            <>
              {subtitle}
              {content}
            </>
          </div>
          <div className={"col-12 col-sm-12 " + imageClass}>
            <>{image}</>
          </div>
        </div>
      )}
      {!hasHeader && (
        <div className="content">
          <div className={"col-12 col-sm-12 container-text " + componentOrder}>
            <>
              {title}
              {subtitle}
              {content}
            </>
          </div>
          <div className={"col-12 col-sm-12 " + imageClass}>
            <>{image}</>
          </div>
        </div>
      )}
    </div>
  );
};

interface HostSplitInformationProps {
  principalClass: string;
  imageClass: string;
  title: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >;
  subtitle: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >;
  content: DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >;
  image?: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  componentColor: string;
  componentOrder: string;
}
